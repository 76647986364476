import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'tab-footer',
    templateUrl: './tab-footer.component.html',
    styleUrls: ['./tab-footer.component.scss']
})

export class TabFooterComponent extends BaseComponent {
    @Input() data: any;
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
	public setData() {
        this.tabChangedEvent.emit(this.data);
	}
}
