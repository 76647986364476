import { Component, EventEmitter, Output } from '@angular/core';

import { SDKTab } from 'sdk-tabs';

import { BaseComponent } from '~/pages/base.component';

import { TabCoreComponent } from '~/core/components/configure/tab-core/tab-core.component';
import { TabHeaderComponent } from '~/core/components/configure/tab-header/tab-header.component';
import { TabNavComponent } from '~/core/components/configure/tab-nav/tab-nav.component';
import { TabFooterComponent } from '~/core/components/configure/tab-footer/tab-footer.component';
import { TabSeoComponent } from '~/core/components/configure/tab-seo/tab-seo.component';

@Component({
    selector: 'configure',
    templateUrl: './configure.component.html',
    styleUrls: ['./configure.component.scss']
})

export class ConfigureComponent extends BaseComponent {
    @Output() applyEvent: EventEmitter<any> = new EventEmitter();
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    public tabs: SDKTab[] = [
        {
            title: "Core Data",
            type: <any>TabCoreComponent,
            inputs: {
                "data": null
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "Nav",
            type: <any>TabNavComponent,
            inputs: {
                "data": null
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "Header",
            type: <any>TabHeaderComponent,
            inputs: {
                "data": null
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "Footer",
            type: <any>TabFooterComponent,
            inputs: {
                "data": null
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
        {
            title: "SEO",
            type: <any>TabSeoComponent,
            inputs: {
                "data": null
            },
            outputs: {
                'tabChangedEvent': (event: any) => {
                    this.tabChanged(event);
                }
            }
        },
    ];

	public message: string = "";
	public json: string = "";

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.json = JSON.stringify(this.appSettings, undefined, 4);

        this.tabs.forEach((tab: any) => {
            tab.inputs.data = this.appSettings;
        });
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public tabChanged(event: any) {
        if (event) {
            this.appSettings = event;
            this.appSettings.user = null;

            this.json = JSON.stringify(this.appSettings, undefined, 4);
        }
    }

    public async apply() {
        this.appSettings.user = null;
        
        await this.saveAppSettings();

        this.applyEvent.emit();
    }

    public copy() {        
        this.message = "AppSettings copied to clipboard!";
    }

    public close() {
        this.closeEvent.emit();
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
}
