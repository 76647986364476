import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

//******************************************************************************
//  Core Pages
//******************************************************************************
import { AuthComponent } from '~/core/pages/auth/auth.component';
import { NotFoundComponent } from '~/core/pages/notfound/notfound.component';

//******************************************************************************
//  Core Components
//******************************************************************************
import { ErrorComponent } from '~/core/components/error/error.component';
import { AccessComponent } from '~/core/components/access/access.component';

export const routes: any[] = [
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'notfound',
        component: NotFoundComponent,
    },
    {
        path: 'access',
        component: AccessComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class CoreRouting { }
