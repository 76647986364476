class Header {
    public custom: boolean = false;
    public height: string = "50px";
    public environment: boolean = true;
}

class Nav {
    public visible: boolean = true;
    public position: string = "left";
    public sizable: boolean = true;
    public appMode: boolean = true;
    public height: string = "50px";
    public items: string = "center";
    public icons: boolean = true;
}

class Footer {
    public visible: boolean = true;
    public custom: boolean = false;
    public height: string = "50px";
}

class SEO {
    public path: string = "";
    public metaTitle: string = "";
    public metaDescription: string = "";
    public metaKeywords: string = "";
    public title: string = "";
    public icon: string = "";
    public isNav: boolean = true;
    public isSubNav: boolean = false;
    public isActive: boolean = true;
}

export class AppSettings {
    public title: string = "";
    public version: string = "";
    public publicAccess: boolean = true;
    public header: Header = new Header();
    public nav: Nav = new Nav();
    public footer: Footer = new Footer();
    public supportLink: string = "";
    public seo: SEO[] = [];
    public roles: any = null;
    public user: any = null;
}
