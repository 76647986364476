import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-select',
	templateUrl: './sdk-select.component.html',
	styleUrls: ['./sdk-select.component.scss']
})

export class SDKSelectComponent {
	/**************************************************************************
	* Input/Output Parameters
	**************************************************************************/
	@Input() label: any; // Text to display to the left/top of dropdown.
	@Input() labelPosition: string = "left"; // Text located to the 'left' or 'top' position of dropdown.
	@Input() labelStyle: any; // Style applied to 'label'.
	@Input() options: any; // Values to select from.
	@Input() optionStyle: any; // Main styling for the dropdown (e.g., font, border, colors, etc.).
	@Input() optionValuesStyle: any; // Main styling for the dropdown values list (e.g., max-height, overflow, etc.).
	@Input() forceOptionBottom: boolean = false; // Forces the dropdown below the value.
	@Input() noValueLabel: string = "..."; // 'No Value' label.
	@Input() noValueDisabled: boolean = true; // Prevent 'No Value' from being selected.
	@Input() displayValue: any; // Property (key) to display as value if options are of type 'object'.
	@Input() multiSelect: boolean = false; // Indicates single or multiple selections.
	@Input() multiValues: boolean = true; // Show multiple values in the dropdown display or 'n selected' message after 2+ values selected.
	@Input() selectedOptions: any; // Values pre-selected in dropdown.
	@Input() resetLabel: string = "[clear]"; // Text to display for 'clearing/resetting' selected options.
	@Input() hoverColor: any; // Color used when mouse hovers over values.
	@Input() selectedColor: any; // Font color used to indicate selected value (single selection dropdown ONLY).
	@Input() selectedBackground: any; // Background color used to indicate selected value (single selection dropdown ONLY).

	@Output() selectChangeEvent: EventEmitter<any> = new EventEmitter(); // Event triggered on selections.

	/**************************************************************************
	* Component Variables
	**************************************************************************/
	@ViewChild("dropdown") dropdown!: ElementRef | undefined;
	@ViewChild("dropdownValue") dropdownValue!: ElementRef | undefined;
	@ViewChild("singleSelect") singleSelect!: ElementRef | undefined;
	@ViewChild("multipleSelect") multipleSelect!: ElementRef | undefined;

	protected showDropdown: boolean = false;
	protected adjustedOptionValuesStyle: any = "";

	private closeTimer: any;
	private allowClose: boolean = false;
	private scrollTop: any = null;

	/**************************************************************************
	* Component Lifecycle Methods
	**************************************************************************/
	protected ngOnInit() {
		if (!this.multiSelect && this.selectedOptions !== undefined && this.selectedOptions !== "" && !Array.isArray(this.selectedOptions)) {
			let tmp = [];

			tmp.push(this.selectedOptions);

			this.selectedOptions = tmp;
		}

		setTimeout(() => {
			this.setStyle();
		}, 1);
	}

	/**************************************************************************
	* Protected Methods
	**************************************************************************/
	protected startClose() {
		this.allowClose = true;

		this.closeTimer = setTimeout(() => {
			if (this.allowClose) {
				this.allowClose = false;
				this.showDropdown = false;
			}
		}, 500);
	}

	protected stopClose() {
		clearTimeout(this.closeTimer);
		this.allowClose = false;
	}

	protected setDropdown() {
		if (!this.optionValuesStyle || this.optionValuesStyle === "") {
			this.adjustedOptionValuesStyle = "";
		}

		let top = this.dropdownValue?.nativeElement.getBoundingClientRect().top;
		let height = this.dropdownValue?.nativeElement.getBoundingClientRect().height - 1;

		if (top <= (window.innerHeight / 2)) {
			this.adjustedOptionValuesStyle = `top: ${height}px; left: -0px;` + this.optionValuesStyle;
		} else {
			this.adjustedOptionValuesStyle = `bottom: ${height}px; left: -0px;` + this.optionValuesStyle;
		}

		if (this.forceOptionBottom) {
			this.adjustedOptionValuesStyle = `top: ${height}px; left: -0px;` + this.optionValuesStyle;
		}

		this.showDropdown = !this.showDropdown;

		this.scrollTop = null;

		setTimeout(() => {
			let singleHeight = this.singleSelect?.nativeElement.scrollHeight / this.options.length;
			let multipleSelect = this.multipleSelect?.nativeElement.scrollHeight / (this.options.length + 1.5);

			this.options.forEach((option: any, index: number) => {
				if (this.selectedOptions && this.selectedOptions !== "") {
					this.selectedOptions.forEach((item: any) => {
						if (JSON.stringify(option) === JSON.stringify(item)) {
							if (!this.scrollTop) {
								this.scrollTop = index;

								if (this.multipleSelect) {
									this.scrollTop += 1.5;
								}
							}
						}
					});
				}
			});

			if (this.singleSelect) {
				this.singleSelect.nativeElement.scrollTop = (this.scrollTop * singleHeight);
			}

			if (this.multipleSelect) {
				this.multipleSelect.nativeElement.scrollTop = (this.scrollTop * multipleSelect);
			}
		}, 10);
	}

	protected valueBuilder(option: any) {
		let re = /\[([^\]]+)\]/g;
		let x = this.displayValue.match(re);
		let value: any = this.displayValue;

		if (x) {
			x.forEach((segment: any) => {
				let z = option[segment.replace("[", "").replace("]", "")];
				value = value.replaceAll(segment, z);
			});
		} else {
			value = option[value];
		}

		return value;
	}

	protected selectionBuilder() {
		let selection: any = "";

		if (this.multiSelect) {
			if (this.multiValues) {
				if (this.displayValue && this.displayValue !== "") {
					let tmp: any = [];

					this.selectedOptions.forEach((item: any) => {
						tmp.push(this.valueBuilder(item));
					});

					selection = tmp.join(", ");
				} else {
					selection = this.selectedOptions;
				}
			} else {
				if (this.selectedOptions.length === 1) {
					selection = (this.displayValue && this.displayValue !== "") ? this.valueBuilder(this.selectedOptions[0]) : this.selectedOptions[0];
				} else {
					selection = this.selectedOptions.length + ' selected';
				}
			}
		} else {
			selection = (this.displayValue && this.displayValue !== "") ? this.valueBuilder(this.selectedOptions[0]) : this.selectedOptions[0];
		}

		return selection;
	}

	protected titleBuilder() {
		let title: any = "";
		let tmp: any = [];

		if (this.selectedOptions && this.selectedOptions.length > 0) {
			this.selectedOptions.forEach((item: any) => {
				if (this.displayValue && this.displayValue !== "") {
					tmp.push(this.valueBuilder(item));
				} else {
					tmp.push(item);
				}
			});

			title = `Selected value(s):\n${tmp.join("\n")}`;
		}

		return title;
	}

	protected isSelected(option: any) {
		let ndx = (this.selectedOptions && this.selectedOptions !== "") ? this.selectedOptions?.findIndex((item: any) => JSON.stringify(item) === JSON.stringify(option)) : -1;

		return (ndx > -1) ? true : false;
	}

	protected selectItem(option: any) {
		let ndx = (this.selectedOptions && this.selectedOptions !== "") ? this.selectedOptions?.findIndex((item: any) => JSON.stringify(item) === JSON.stringify(option)) : -1;

		if (ndx > -1) {
			if (this.multiSelect || !this.noValueDisabled) {
				this.selectedOptions.splice(ndx, 1);
			}
		} else {
			if (!this.multiSelect || !this.selectedOptions) {
				this.selectedOptions = [];
			}

			this.selectedOptions.push(option);
		}

		if (!this.multiSelect && this.noValueDisabled) {
			this.showDropdown = false;
		}

		this.selectChangeEvent.emit(this.selectedOptions);
	}

	protected clearSelections() {
		this.selectedOptions = [];

		this.selectChangeEvent.emit(this.selectedOptions);
	}

	protected allSelections() {
		this.selectedOptions = this.options.slice(0);

		this.selectChangeEvent.emit(this.selectedOptions);
	}

	/**************************************************************************
	* Protected Methods
	**************************************************************************/
	private setStyle() {
		let element = this.dropdown?.nativeElement;

		if (element) {
			element.style.setProperty("--hover-color", this.hoverColor);
			element.style.setProperty("--selected-color", this.selectedColor);
			element.style.setProperty("--selected-background", this.selectedBackground);
		}
	}
}
