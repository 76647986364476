import { Component, EventEmitter, Input, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { FormulaOperation, SDKDataGridFormula } from '../../models/datagrid-formula';
import { SDKDataGridMessage } from '../../models/datagrid-message';

import { Clone } from '../../utils/clone';

@Component({
    selector: 'sdk-datagrid-formula-option',
    templateUrl: './formula-option.component.html',
    styleUrls: ['./formula-option.component.scss']
})

export class SDKDataGridFormulaOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() columns: SDKDataGridColumn[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected columnList: SDKDataGridColumn[] = [];
    protected formulaList: SDKDataGridFormula[] = [];
    protected operationList: any = [
        { Operation: "Avg", DisplayName: "Average" },
        { Operation: "Rng", DisplayName: "Range (High/Low)" },
        { Operation: "Sum", DisplayName: "Summarize" }
    ];

    private _columns: SDKDataGridColumn[] = [];

    /**************************************************************************
    * Message Variables
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
    protected ngOnChanges(_args: any) {
        if (_args.columns?.currentValue !== undefined) {
            this._columns = Clone.deepCopy(this.columns);

            this.initialize();
        }
    }

    /**************************************************************************
    * Setup Methods
    **************************************************************************/
    protected initialize() {
        this.formulaList = [];
        this.columnList = [];

        this._columns
            .filter((column: SDKDataGridColumn) => column.isVisible && !column.isAction && column.Formulas)
            .forEach((column: SDKDataGridColumn) => {
                column.Formulas.forEach((formula: any) => {
                    this.formulaList.push({
                        Name: column.Name,
                        Operation: formula.operation,
                        Format: formula.format,
                        Value: formula.value
                    });
                });
            });

        this._columns.filter((column: SDKDataGridColumn) => column.isVisible && !column.isAction).forEach((column: SDKDataGridColumn) => {
            this.columnList.push({ Name: column.Name, DisplayName: this.getColumnName(column)});
        });
    }

    protected getColumnName(column: SDKDataGridColumn) {
        let originalName = column.Name;

        if (column.FriendlyName && column.FriendlyName !== "") {
            originalName = column.FriendlyName;
        } else if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    protected getSelectedColumn(formula: any) {
        let ndx: number = this._columns.findIndex((column: SDKDataGridColumn) => column.Name === formula.Name);

        if (ndx > -1) {
            return [{ "DisplayName": this._columns[ndx].DisplayName }];
        } else {
            return [];
        }
    }

    protected getSelectedOperation(formula: any) {
        let ndx: number = this.operationList.findIndex((operation: any) => operation.Operation === formula.Operation);

        if (ndx > -1) {
            return [{ "DisplayName": this.operationList[ndx].DisplayName }];
        } else {
            return [];
        }
    }

    private validate() {
        let valid: boolean = true;

        this.formulaList.forEach((formula: SDKDataGridFormula) => {
            if (formula.Name === "") valid = false;
            if (formula.Operation === FormulaOperation.None) valid = false;
        })

        return valid;
    }

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected addFormula() {
        this.formulaList.push({
            Name: "",
            Operation: FormulaOperation.None,
            Format: 0,
            Value: 0
        });
    }

    protected deleteFormula(index: number) {
        this.formulaList.splice(index, 1);
    }

    protected addColumn(event: any, index: number) {
        this.formulaList[index].Name = event[0].Name;
    }

    protected addOperation(event: any, index: number) {
        this.formulaList[index].Operation = event[0].Operation;
    }

    protected addFormat(event: any, index: number) {
        this.formulaList[index].Format = (event.target.value === "" ? 0 : event.target.value);
    }

    protected drop(event: any) {
        moveItemInArray(this.formulaList, event.previousIndex, event.currentIndex);
    }

    protected reset() {
        this.message = {
            Title: "Reset Formulas",
            Text: "Are you sure?",
            OKText: "YES",
            CancelText: "NO",
            OK: () => {
                this.showMessage = false;

                this._columns.forEach((column: SDKDataGridColumn) => {
                    column.Formulas = column._original.Formulas;
                });

                this.initialize();
            },
            Cancel: () => {
                this.showMessage = false;
            }
        };

        this.showMessage = true;
    }

    protected apply() {
        let cont: boolean = true;

        if (!this.validate()) {
            cont = confirm("Some formulas are missing data. Contniue anyway?");
        }

        if (cont) {
            this._columns.forEach((column: SDKDataGridColumn) => {
                column.Formulas = column._original.Formulas;
            });

            this.formulaList.forEach((formula: SDKDataGridFormula) => {
                let ndx = this._columns.findIndex((column: SDKDataGridColumn) => column.Name === formula.Name);

                if (ndx > -1) {
                    if (!this._columns[ndx].Formulas) {
                        this._columns[ndx].Formulas = [];
                    }

                    this._columns[ndx].Formulas.push({
                        operation: formula.Operation,
                        format: formula.Format,
                        value: formula.Value
                    });
                }
            })

            this.applyEvent.emit({ option: "Formulas", columns: this._columns });
        }
    }

    protected close() {
        this.closeEvent.emit();
    }
}
