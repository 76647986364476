import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    constructor(private http: HttpClient) { }

    public async getUserPhoto(): Promise<any> {
        const p = new Promise<any>((resolve, reject) => {
            let photo: any = "x";

            try {
                let url = "https://graph.microsoft.com/v1.0/me/photo/$value"
                let headers: HttpHeaders = new HttpHeaders({
                    Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`
                });
                let stream$ = this.http.get(url, { headers: headers, responseType: "blob" });

                if (stream$) {
                    lastValueFrom(stream$).then((response: any) => {
                        if (response) {
                            let reader: FileReader = new FileReader();
    
                            reader.readAsDataURL(response);
                            reader.onloadend = () => {
                                photo = reader.result;
    
                                resolve(photo);
                            }
                        }
                    });
                }
            } catch (error: any) {
                reject(error);
            }
        });

        return await p;
    }

    public async getUserGroups(role: any): Promise<any> {
        let results: any = null;

        try {
            let url = `https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, '${role}')`;
            let headers: HttpHeaders = new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`,
                ConsistencyLevel: "eventual"
            });
            let stream$ = this.http.get(url, { headers: headers });

            results = await lastValueFrom(stream$);
        } catch (error: any) {
            throw error;
        }

        return results;
    }
}
