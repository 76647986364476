<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Formulas</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <button class="sdk-button button-left" (click)="addFormula()">Add formula</button>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div *ngIf="formulaList.length === 0" class="no-records">
            <span>Add a formula.</span>
        </div>
        <div class="box" *ngFor="let formula of formulaList; let i = index" cdkDragBoundary=".list" cdkDrag>
            <div class="delete">
                <span class="sdk-icon" (click)="deleteFormula(i)">delete</span>
            </div>
            <div>
                <div class="column">
                    <sdk-select
                        [options]="columnList"
                        optionStyle="border-radius: 4px;"
                        [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
                        noValueLabel="Select column..."
                        displayValue="DisplayName"
                        [selectedOptions]="getSelectedColumn(formula)"
                        (selectChangeEvent)="addColumn($event, i)">
                    </sdk-select>
                </div>
                <div class="operation-precision">
                    <div class="operation">
                        <sdk-select
                            [options]="operationList"
                            optionStyle="border-radius: 4px;"
                            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
                            noValueLabel="Select operator..."
                            displayValue="DisplayName"
                            [selectedOptions]="getSelectedOperation(formula)"
                            (selectChangeEvent)="addOperation($event, i)">
                        </sdk-select>
                    </div>
                    <div class="precision">
                        <span>#.</span>
                        <input [value]="formula.Format" (keypress)="($event.charCode >= 48 && $event.charCode < 58) || $event.charCode === 8" (blur)="addFormat($event, i)" />
                    </div>
                </div>
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="sdk-button delete" (click)="reset()">Reset</button>
        <button class="sdk-button" (click)="apply()">Apply</button>
    </div>
</div>

<!-- Lock -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
