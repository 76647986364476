<div class="content">
    <div class="header">
        <div class="title">{{ pageTitle }}</div>
    </div>
    <div class="data">
        <ng-container *ngFor="let route of routes">
			<div class="route">
				<div class="icon" [title]="route.title" (click)="navigateTo(route)">{{ route.icon }}</div>
				<div class="title" (click)="navigateTo(route)">{{ route.title }}</div>
			</div>
            <div class="description">{{ route.metaDescription }}</div>
		</ng-container>
    </div>
</div>
