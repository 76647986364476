<div class="content">
    <div class="data">
        <div class="pages">
            <div>
                <sdk-select [options]="pages" [optionValuesStyle]="'max-height: 250px'" label="Page" labelStyle="font-weight: 600; color: var(--blue); white-space: nowrap;" (selectChangeEvent)="setPage($event)"></sdk-select>
            </div>
        </div>

        <div *ngIf="page" class="page">
            <div>
                <span class="label">Path</span>
                <span class="icon" [title]="'Path of the page.\nNOTE: For the home page, leave blank.'">info</span>
                <sdk-textbox [value]="page.path" style="color: var(--gray)" (changeCallBackEvent)="page.path = $event; setData()"></sdk-textbox>
            </div>

            <div>
                <span class="label">Title</span>
                <span class="icon" [title]="'Title of the page.'">info</span>
                <sdk-textbox [value]="page.title" style="color: var(--gray)" (changeCallBackEvent)="page.title = $event; setData()"></sdk-textbox>
            </div>

            <div>
                <span class="label">Icon</span>
                <span class="icon" [title]="'Icon used in the navigation menu.'">info</span>
                <sdk-textbox [value]="page.icon" style="color: var(--gray)" (changeCallBackEvent)="page.icon = $event; setData()"></sdk-textbox>
            </div>

            <div>
                <span class="label">In Nav</span>
                <span class="icon" [title]="'Show page in the navigation menu.'">info</span>
                <br />
                <span class="icon checkbox" (click)="page.isNav = !page.isNav; setData()">{{ page.isNav ? 'check_box' : 'check_box_outline_blank' }}</span>
            </div>

            <div>
                <span class="label">Is Sub Nav</span>
                <span class="icon" [title]="'Is page a sub-navigation item.'">info</span>
                <br />
                <span class="icon checkbox" (click)="page.isSubNav = !page.isSubNav; setData()">{{ page.isSubNav ? 'check_box' : 'check_box_outline_blank' }}</span>
            </div>

            <div>
                <span class="label">Active</span>
                <span class="icon" [title]="'Is the page active (shown in app).'">info</span>
                <br />
                <span class="icon checkbox" (click)="page.isActive = !page.isActive; setData()">{{ page.isActive ? 'check_box' : 'check_box_outline_blank' }}</span>
            </div>

            <div>
                <span class="label">META Title</span>
                <span class="icon" [title]="'Text used for the title of the page in the browser tab.'">info</span>
                <sdk-textbox [value]="page.metaTitle" style="color: var(--gray)" (changeCallBackEvent)="page.metaTitle = $event; setData()"></sdk-textbox>
            </div>

            <div>
                <span class="label">META Description</span>
                <span class="icon" [title]="'Text used for the description of the page.'">info</span>
                <sdk-textbox [value]="page.metaDescription" style="color: var(--gray)" [multiLine]="true" height="100px" (changeCallBackEvent)="page.metaDescription = $event; setData()"></sdk-textbox>
            </div>

            <div>
                <span class="label">META Keywords</span>
                <span class="icon" [title]="'Keywords used for the keywords of the page.'">info</span>
                <sdk-textbox [value]="page.metaKeywords" style="color: var(--gray)" [multiLine]="true" height="100px" (changeCallBackEvent)="page.metaKeywords = $event; setData()"></sdk-textbox>
            </div>
        </div>
    </div>
</div>
