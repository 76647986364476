import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { AppConfig } from '~/core/models/appconfig';

const FILE = "assets/config.json";

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private config: AppConfig = new AppConfig();
    private http: HttpClient;

    constructor(private httpHandler: HttpBackend) {
        this.http = new HttpClient(this.httpHandler);
    }

    public async init(): Promise<boolean> {
        const p = new Promise<boolean>((resolve, reject) => {
            try {
                let stream$ = this.http.get(FILE);

                lastValueFrom(stream$).then((data: any) => {
                    this.config = data;

                    resolve(true);
                });
            } catch(error: any) {
                reject(error);
            }
        });

        return await p;
    }

    public getConfig(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0])) {
            return this.config;
        }

        if (!Array.isArray(key)) {
            key = key.split('.');
        }

        return key.reduce((acc: any, current: string) => acc[current], this.config);
    }
}