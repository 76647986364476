<div class="menu">
	<div class="icon" (click)="toggleMenu(true)">{{ expand ? 'close' : 'menu' }}</div>
</div>

<!-- TOP NAV -->
<div #nav *ngIf="appSettings.nav.position === 'top'" class="top">
	<div class="items" [ngClass]="{ expand: expand }">
		<ng-container *ngFor="let item of navItems">
			<div class="item" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }">
				<div *ngIf="appSettings.nav.icons" class="icon" [title]="item.title" [ngClass]="{ active: activeItem && item.title === activeItem.title }" (click)="navigateTo(item)">{{ item.icon }}</div>
				<div class="title" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }" (click)="navigateTo(item)">{{ item.title }}</div>
			</div>
		</ng-container>
	</div>
</div>

<!-- LEFT NAV -->
<div #nav *ngIf="appSettings.nav.position === 'left'" class="left">
	<div class="items" [ngClass]="{ expand: expand }">
		<ng-container *ngFor="let item of navItems">
			<div class="item" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }">
				<div class="icon" [title]="item.title" [ngClass]="{ active: activeItem && item.title === activeItem.title }" (click)="navigateTo(item)">{{ item.icon }}</div>
				<div class="title" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }" (click)="navigateTo(item)">{{ item.title }}</div>

				<ng-container *ngFor="let subItem of navSubItems">
					<div *ngIf="subItem.parent === item.title" class="sub" [ngClass]="{ expand: expand }">
						<div class="arrow" [ngClass]="{ active: activeItem && subItem.title === activeItem.title }">►</div>
						<div class="line"></div>
						<div class="icon" [ngClass]="{ active: activeItem && subItem.title === activeItem.title }" [title]="subItem.title" (click)="navigateTo(subItem)">{{ subItem.icon }}</div>
						<div class="title" [ngClass]="{ active: activeItem && subItem.title === activeItem.title }" (click)="navigateTo(subItem)">{{ subItem.title }}</div>
					</div>
				</ng-container>
			</div>
		</ng-container>

		<div *ngIf="appSettings.nav.appMode || appSettings.nav.sizable" class="toggle" [ngClass]="{ expand: expand }">
			<div *ngIf="appSettings.nav.appMode" [title]="appTitle" class="icon" (click)="toggleFullscreen()">{{ appIcon }}</div>
			<div *ngIf="appSettings.nav.sizable" [title]="expand ? 'Collapse Menu' : 'Expand Menu'" class="icon" (click)="toggleMenu()">{{ expand ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}</div>
		</div>
	</div>
</div>

<core-footer *ngIf="!appSettings.footer.custom" [ngClass]="{ expand: expand }"></core-footer>
<footer *ngIf="appSettings.footer.custom" [ngClass]="{ expand: expand }"></footer>
