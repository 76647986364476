import { Injectable } from '@angular/core';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService { // https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
    private appInsights: ApplicationInsights | undefined;

    constructor() {
        this.initializeService();
    }

    private initializeService () {
        if (!this.appInsights && environment.instrumentationKey && environment.instrumentationKey !== "") {
            try {
                this.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: environment.instrumentationKey,
                        enableAutoRouteTracking: true
                    }
                });

                this.appInsights.loadAppInsights();
            }
            catch (ex) {
                console.error(`ERROR initializing AppInsights: ${ex}`);
            }
        }
    }

    public logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackEvent({ name: name }, properties);
    }
}