<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Filters</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="content no-actions">
        <div class="box" [ngClass]="{ 'checkbox': filter.Type === 'Any' }" *ngFor="let filter of filterList">
            <div>
                <div *ngIf="['text', 'text-only', 'list'].includes(filter.ValueType)" class="column">
                    <div class="name">{{ filter.DisplayName }}</div>
                </div>
                <div *ngIf="filter.ValueType === 'text-only' && (!filter.Operation || filter.Operation === '')" class="operation-value no-operation">
                    <div class="value no-operation">
                        <input [value]="filter.Value" (input)="addValue($event, filter)" />
                    </div>
                </div>
                <div *ngIf="filter.ValueType === 'checkbox'" class="column-checkbox">
                    <div class="all-select" (click)="toggleAny(filter)">
                        <input type="checkbox" class="select" [checked]="filter.Value === 'true' ? 'checked' : ''" />
                        <span>{{ filter.DisplayName }}</span>
                    </div>
                </div>
                <div *ngIf="['text', 'list'].includes(filter.ValueType)" class="operation-value">
                    <div class="operation">
                        <sdk-select
                            [options]="getFilters(filter)"
                            optionStyle="border-radius: 4px;"
                            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
                            noValueLabel="Select operator..."
                            [selectedOptions]="filter.Operation"
                            (selectChangeEvent)="addOperation($event, filter)">
                        </sdk-select>
                    </div>
                    <div class="value">
                        <input *ngIf="filter.ValueType === 'text'" [value]="filter.Value" (input)="addValue($event, filter)" />

                        <sdk-select *ngIf="filter.ValueType === 'list' && !filter.MultiSelect"
                            [options]="filter.ValueList"
                            optionStyle="border-radius: 4px;"
                            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
                            noValueLabel="[All values]"
                            [noValueDisabled]=false
                            [selectedOptions]="filter.Value"
                            (selectChangeEvent)="selectedValue($event, filter)">
                        </sdk-select>

                        <sdk-select *ngIf="filter.ValueType === 'list' && filter.MultiSelect"
                            [options]="filter.ValueList"
                            optionStyle="border-radius: 4px;"
                            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow: auto;'"
                            noValueLabel="[All values]"
                            [noValueDisabled]=false
                            [multiSelect]=filter.MultiSelect
                            [multiValues]=false
                            [selectedOptions]="filter.Value"
                            (selectChangeEvent)="selectedValue($event, filter)">
                        </sdk-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="sdk-button delete" (click)="reset()">Reset</button>
        <button class="sdk-button" (click)="apply()">Apply</button>
    </div>
</div>

<!-- Lock -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
