export enum Filters {
    Equals = "Equals (=)",
    NotEquals = "Not Equals (!=)",
    Empty = "Empty",
    NotEmpty = "Not Empty",
    Contains = "Contains",
    NotContains = "Not Contains",
    GreaterThan = "Greater Than (>)",
    GreaterThanOrEqual = "Greater Than/Equal To (>=)",
    LessThan = "Less Than (<)",
    LessThanOrEqual = "Less Than/Equal To (<=)",
    InList = "In List"
}

export enum FilterType {
    Textbox = "text",
    TextboxOnly = "text-only",
    Checkbox = "checkbox",
    Dropdown = "list"
}
