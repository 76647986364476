import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'tab-core',
    templateUrl: './tab-core.component.html',
    styleUrls: ['./tab-core.component.scss']
})

export class TabCoreComponent extends BaseComponent {
    @Input() data: any;
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('sdktextbox') sdktextbox!: any;

    public role: string = "";
    public adjustRole: string = "";

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
	public setData() {
        this.tabChangedEvent.emit(this.data);
	}

	public setValue(event: any) {
        this.role = event;
	}

	public setAdjustValue(event: any) {
        this.role = event;
        this.adjustRole = event;
        this.sdktextbox.text.nativeElement.focus();
	}

	public addValue() {
        let ndx = this.data.roles.findIndex((value: any) => value === this.role.trim());

        if (ndx === -1 && this.role.trim() !== "") {
            this.data.roles.push(this.role.trim());
        }

        this.deleteValue(this.adjustRole);

        this.sortRoles();
        this.setData();

        this.role = "";
        this.adjustRole = "";
        this.sdktextbox.text.nativeElement.focus();
	}

	public deleteValue(event: any) {
        let ndx = this.data.roles.findIndex((value: any) => value === event);

        if (ndx > -1) {
            this.data.roles.splice(ndx, 1);
        }

        this.setData();
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private sortRoles() {
        this.data.roles.sort((a: any, b: any) => a.localeCompare(b));
    }
}
