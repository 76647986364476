import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Clone } from '../../utils/clone';

import { SDKDataGridSettings } from '../../models/datagrid-settings';
import { SDKDataGridColumn } from '../../models/datagrid-column';
import { SDKDataGridCustomFilter } from '../../models/datagrid-custom-filter';
import { SDKDataGridMessage } from '../../models/datagrid-message';

@Component({
    selector: 'sdk-datagrid-settings-option',
    templateUrl: './settings-option.component.html',
    styleUrls: ['./settings-option.component.scss'],
    encapsulation: ViewEncapsulation.None // Used for innerHtml formatting on 'viewerText' property.
})

export class SDKDataGridSettingsOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() settings: SDKDataGridSettings[] = [];
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() customFilters: SDKDataGridCustomFilter[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() saveEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected activeSetting: SDKDataGridSettings = new SDKDataGridSettings();
    protected settingsList: SDKDataGridSettings[] = [];
    protected name: string = "";
    protected viewerText: string = "";
    protected msg: string = "";
    protected error: string = "";

    private activeIndex: number = 0;

    private _settings: SDKDataGridSettings[] = [];
    private _columns: SDKDataGridColumn[] = [];
    private _customFilters: SDKDataGridCustomFilter[] = [];

    /**************************************************************************
    * Message Variables
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    protected ngOnChanges(_args: any) {
        this._settings = Clone.deepCopy(this.settings);
        this._columns = Clone.deepCopy(this.columns);
        this._customFilters = Clone.deepCopy(this.customFilters);

        this.initialze();
    }

    /**************************************************************************
    * Setup Methods
    **************************************************************************/
    private initialze() {
        this.settingsList = this._settings;

        // Reset current settings based on columns and custom filters.
        let currentSetting: SDKDataGridSettings = {
            Name: "[Current Settings]",
            Columns: this._columns,
            CustomFilters: this._customFilters,
            Active: false
        }

        let ndx = this.settingsList.findIndex((settings: SDKDataGridSettings) => settings.Name === "[Current Settings]");

        if (ndx === -1) {
            this.settingsList.unshift(currentSetting);
        } else {
            this.settingsList[ndx] = currentSetting;
        }

        // Pre-load any settings that are flagged as 'active'.
        let activeNdx = this.settingsList.findIndex((setting: SDKDataGridSettings) => setting.Active === true);

        if (activeNdx > -1) {
            this.activeIndex = activeNdx;
        }

        this.activeSetting = this.settingsList[this.activeIndex];
        this.name = this.activeSetting.Name;

        this.validateSettings();
        this.setViewerText();
    }

    private saveSetting() {
        this.settingsList[this.activeIndex].Name = this.name;

        if (this.activeIndex === 0) {
            let currentSetting: SDKDataGridSettings = Clone.deepCopy(this.settingsList[this.activeIndex]);

            currentSetting.Name = "[Current Settings]";

            this.settingsList.unshift(currentSetting);
        }

        this.resetProperties();

        this.activeIndex = this.settingsList.findIndex((setting: any) => setting.Name === this.name) + 1;

        this.saveEvent.emit(this.settingsList);

        this.msg = "Settings have been saved.";
    }

    private sortSettings() {
        this.settingsList.sort((a: SDKDataGridSettings, b: SDKDataGridSettings) => {
            const aName = a.Name.toUpperCase();
            const bName = b.Name.toUpperCase();

            if (aName > bName) {
                return 1;
            }

            if (aName < bName) {
                return -1;
            }

            return 0;
        });
    }

    private resetProperties() {
        this.sortSettings();

        this.settingsList.forEach((setting: SDKDataGridSettings) => {
            setting.Active = false;
        });
    }

    private setViewerText() {
        setTimeout(() => {
            this.viewerText = `Columns:<br />`;

            this.viewerText += "<ul>";
            this.activeSetting.Columns.filter((column: SDKDataGridColumn) => !column.isAction).forEach((column: SDKDataGridColumn) => {
                this.viewerText += `<li>${(column.FriendlyName !== "") ? column.FriendlyName : (column.DisplayName !== "") ? column.DisplayName : column.Name} (${column.isVisible ? "visible" : "hidden"})</li>`;
            });
            this.viewerText += "</ul>";

            this.viewerText += `<br />Sorts:<br />`;

            this.viewerText += "<ul>";
            this.activeSetting.Columns.forEach((column: SDKDataGridColumn) => {
                if (column.Sort) {
                    this.viewerText += `<li>${(column.DisplayName !== "") ? column.DisplayName : column.Name} (${column.Sort.direction})</li>`;
                }
            });
            this.viewerText += "</ul>";

            this.viewerText += `<br />Filters:<br />`;

            this.viewerText += "<ul>";
            this.activeSetting.CustomFilters.forEach((filter: SDKDataGridCustomFilter) => {
                if (filter.Filter) {
                    if (filter.Filter.operation) {
                        this.viewerText += `<li>${(filter.DisplayName !== "") ? filter.DisplayName : filter.Name} (${filter.Filter.operation}: ${filter.Filter.value})</li>`;
                    } else {
                        this.viewerText += `<li>${(filter.DisplayName !== "") ? filter.DisplayName : filter.Name} (${filter.Filter.value})</li>`;
                    }
                }
            });
            this.activeSetting.Columns.forEach((column: SDKDataGridColumn) => {
                if (column.Filter) {
                    if (column.Filter.operation) {
                        this.viewerText += `<li>${(column.DisplayName !== "") ? column.DisplayName : column.Name} (${column.Filter.operation}: ${column.Filter.value})</li>`;
                    } else {
                        this.viewerText += `<li>${(column.DisplayName !== "") ? column.DisplayName : column.Name} (${column.Filter.value})</li>`;
                    }
                }
            });
            this.viewerText += "</ul>";

            this.viewerText += `<br />Formulas:<br />`;

            this.viewerText += "<ul>";
            this.activeSetting.Columns.forEach((column: SDKDataGridColumn) => {
                if (column.Formulas) {
                    column.Formulas.forEach((formula: any) => {
                        this.viewerText += `<li>${(column.DisplayName !== "") ? column.DisplayName : column.Name} (${formula.Operation}: #.${formula.Format})</li>`;
                    });
                }
            });
            this.viewerText += "</ul>";
        }, 10);
    }

    private validateSettings() {
        let issue: boolean = false;
        this.error = "";

        if (this._columns.length !== this.activeSetting.Columns.length) {
            issue = true;
        }

        this.activeSetting.Columns.forEach((column: SDKDataGridColumn) => {
            let ndx = this._columns.findIndex((c: SDKDataGridColumn) => c.Name === column.Name);

            if (ndx === -1) {
                issue = true;
                column.FriendlyName = column.Name + '<span> <-- Does NOT exist!</span>';
            }
        });

        this._columns.forEach((column: SDKDataGridColumn) => {
            let ndx = this.activeSetting.Columns.findIndex((c: SDKDataGridColumn) => c.Name === column.Name);

            if (ndx === -1) {
                issue = true;
                this.activeSetting.Columns.push({
                    Name: column.Name,
                    DisplayName: "",
                    FriendlyName: column.Name + '<span> <-- Missing!</span>',
                    isVisible: column.isVisible
                });
            }
        });

        if (this._customFilters.length !== this.activeSetting.CustomFilters.length) {
            issue = true;
        }

        this.activeSetting.CustomFilters.forEach((filter: SDKDataGridCustomFilter) => {
            let ndx = this._customFilters.findIndex((f: SDKDataGridCustomFilter) => f.Name === filter.Name);

            if (ndx === -1) {
                issue = true;
                filter.DisplayName = filter.Name + '<span> <-- Does NOT exist!</span>';
            }
        });

        this._customFilters.forEach((filter: SDKDataGridCustomFilter) => {
            let ndx = this.activeSetting.CustomFilters.findIndex((f: SDKDataGridCustomFilter) => f.Name === filter.Name);

            if (ndx === -1) {
                issue = true;
                this.activeSetting.CustomFilters.push({
                    Name: filter.Name,
                    DisplayName: filter.Name = '<span> <-- Missing!</span>',
                    Type: filter.Type,
                    Filter: {
                        operation: "",
                        value: ""
                    }
                });
            }
        });

        if (issue) {
            this.error = "There are issues with these settings!";
        }
    }

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected setName(event: any) {
        this.name = event.trim();
    }

    protected save() {
        if (this.name !== "") {
            let dup = this.settingsList.findIndex((setting: SDKDataGridSettings) => setting.Name === this.name);

            if (dup > -1) {
                this.message = {
                    Title: "Duplicate Settings",
                    Text: `'${this.name}' already exists. Would you like to override it?`,
                    OKText: "YES",
                    CancelText: "NO",
                    OK: () => {
                        this.showMessage = false;

                        this.settingsList.splice(dup, 1);

                        this.saveSetting();
                    },
                    Cancel: () => {
                        this.showMessage = false;
                    }
                };

                this.showMessage = true;
            } else {
                this.saveSetting();
            }
        }
    }

    protected remove() {
        if (this.activeSetting.Name !== "[Current Settings]") {
            this.message = {
                Title: "Delete Settings",
                Text: `Are you sure you want to delete the '${this.name}' settings?`,
                OKText: "YES",
                CancelText: "NO",
                OK: () => {
                    this.showMessage = false;

                    this.settingsList.splice(this.activeIndex, 1);

                    this.activeIndex = 0;

                    this.saveEvent.emit(this.settingsList);

                    this.msg = "Settings have been deleted.";
                },
                Cancel: () => {
                    this.showMessage = false;
                }
            };

            this.showMessage = true;
        }
    }

    protected load(event: any) {
        this.activeIndex = this.settingsList.findIndex((setting: SDKDataGridSettings) => setting.Name === event[0].Name);
        this.activeSetting = this.settingsList[this.activeIndex];
        this.name = this.activeSetting.Name;

        this.validateSettings();
        this.setViewerText();
    }

    protected apply() {
        this.resetProperties();

        // Set selected settings as active.
        if (this.activeSetting.Name !== "[Current Settings]") {
            this.activeSetting.Active = true;
        }

        this.saveEvent.emit(this.settingsList);
        this.applyEvent.emit({ columns: this.activeSetting.Columns, customFilters: this.activeSetting.CustomFilters });
    }

    protected close() {
        this.closeEvent.emit();
    }
}
