import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss']
})

export class SitemapComponent extends BaseComponent {
    public routes: any = [];
    public subRoutes: any = [];

    constructor() {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.getRoutes();
    }

    public navigateTo(route: any) {
        window.location.href = `${window.location.origin}/${route.path}`;
    }


    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getRoutes() {
        this.routes = [];
        this.subRoutes = [];

        this.appSettings.seo.forEach((route: any) => {
            if (route.isActive) {
                this.routes.push(route);
            }
        });
    }
}
