import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Sorts } from './enums/sorts';

import { Clone } from './utils/clone';

import { SDKDataGridColumn } from './models/datagrid-column';
import { SDKDataGridOptions, StorageType } from './models/datagrid-options';
import { SDKDataGridCustomFilter } from './models/datagrid-custom-filter';
import { SDKDataGridSettings } from './models/datagrid-settings';
import { SDKDataGridMessage } from './models/datagrid-message';

@Component({
	selector: 'sdk-datagrid',
	templateUrl: './sdk-datagrid.component.html',
	styleUrls: ['./sdk-datagrid.component.scss']
})
export class SDKDatagridComponent {
	/**************************************************************************
	* Input/Output Parameters for updating the Grid
	**************************************************************************/
	@Input() updateGrid: boolean = false; // Applies changes/data to the grid.
	@Output() updateGridChange = new EventEmitter<boolean>(); // Callback to set updateGrid variable.

	/**************************************************************************
	* Input/Output Parameters for editing a record
	**************************************************************************/
	@Input() editRecordIndex: number | null | undefined; // The record that is currently being edited.
	@Output() editRecordIndexChange = new EventEmitter<number | null | undefined>(); // Callback to set editRecordIndex variable.

	/**************************************************************************
	* Input/Output Parameters for dataset change
	**************************************************************************/
	@Input() datasets: string[] = []; // List of datasets.
	@Input() activeDataset: string = ""; // The current selected dataset.
	@Output() activeDatasetChange = new EventEmitter<string>(); // Callback to set activeDataset variable.

	/**************************************************************************
	* Input/Output Parameters for updating columns
	**************************************************************************/
	@Input() columns: SDKDataGridColumn[] = []; // The columns to be used for the grid.
	@Input() columnHeaderStyle: string = ""; // Style used for column headers.
	@Output() columnsChange = new EventEmitter<SDKDataGridColumn[]>(); // Callback to set columns variable.

	/**************************************************************************
	* Input Parameters for rows
	**************************************************************************/
	@Input() detailTemplate!: TemplateRef<any>; // Embedded component for row detail.

	/**************************************************************************
	* Input/Output Parameters for updating custom filters
	**************************************************************************/
	@Input() customFilters: SDKDataGridCustomFilter[] = []; // Custom filters added to standard filter provided in loaddata callback (filters).
	@Output() customFiltersChange = new EventEmitter<SDKDataGridCustomFilter[]>(); // Callback to set custom filters variable.

	/**************************************************************************
	* Input/Output Parameters for exporting data
	**************************************************************************/
	@Input() dataExport: any = ""; // The data to export.
	@Output() dataExportChange = new EventEmitter<any>(); // Callback to set dataExport variable.

	/**************************************************************************
	* Input/Output Parameters for settings data
	**************************************************************************/
	@Input() settings: SDKDataGridSettings[] = []; // Options that are saved outside the grid. This value should be set to the value retieved from the datagrid @Output method "settingsSavedEvent". Consider it a passthrough value.
	@Input() clearSettingsCache: boolean = false; // Clears any grid storage of settings.
	@Output() savedSettingsEvent: EventEmitter<SDKDataGridSettings[]> = new EventEmitter(); // Used as a callback to save settings.
	@Output() clearSettingsCacheChange = new EventEmitter<boolean>(); // Callback to set clearSettingsCache variable.

	/**************************************************************************
	* Required Output Parameters
	**************************************************************************/
	@Output() loadDataEvent = new EventEmitter<any>(); // Callback with grid changes.

	/**************************************************************************
	* Optional Input/Output Parameters
	**************************************************************************/
	@Input() name: string = ""; // Unique name of the grid (per page).
	@Input() title: string = ""; // Title of the grid.
	@Input() titleStyle: string = ""; // Style used for title.
	@Input() options: SDKDataGridOptions | undefined; // Grid options.
	@Input() fontFamily: string = ""; // font-family used for grid.
	@Input() data: any = ""; // The data to display in the grid.
	@Input() rowValues: number[] = [10, 25, 50, 100, 500, 1000]; // Sets the values used for records per page.
	@Input() defaultRows: number = 100; // Default value for records per page.
	@Input() rows: number | undefined; // Current rows in dataset.
	@Input() page: number | undefined; // Current page of dataset.
	@Input() total: number | undefined; // Total records in dataset.

	@Output() saveGridDataEvent: EventEmitter<any> = new EventEmitter(); // Callback for saving changes to data.
	@Output() deleteGridDataEvent: EventEmitter<any> = new EventEmitter(); // Callback for deleting data.
	@Output() selectedRowsChangedEvent: EventEmitter<any[]> = new EventEmitter(); // Callback for row changes.
	@Output() selectedRowActionEvent: EventEmitter<any> = new EventEmitter(); // // Callback for row actions. NOTE: The return object is { record: [the record], index: [the record index] }

	/**************************************************************************
	* Addon Parameters (Option Icon/Modal Window)
	**************************************************************************/
	@Input() optionAddons: Type<any>[] = [];
	@Input() windowAddons: Type<any>[] = [];

	/**************************************************************************
	* Processing Parameters
	**************************************************************************/
	@Input() error: string = ""; // Any errors that occur during processing.
	@Input() isDebug: boolean = false; // Turns on debugging.

	/**************************************************************************
	* Component Variables
	**************************************************************************/
	@ViewChild('sdkdatagrid') sdkdatagrid!: ElementRef<any>;
	@ViewChild('sdkdatagridcontent') sdkdatagridcontent!: ElementRef<any>;

	protected showGrid: boolean = false;
	protected editGrid: boolean = false;
	protected totalPages: Array<string> = new Array(0);
	protected deleteRecords: Array<number> = new Array(0);
	protected editRecords: boolean = false;

	/**************************************************************************
	* Component Variables based on Input parameters
	**************************************************************************/
	protected _editRecordIndex: number | null | undefined = undefined;
	protected _datasets: string[] = [];
	protected _activeDataset: string = "";
	protected _selectedDataset: string = "";
	protected _name: string = "";
	protected _options: SDKDataGridOptions = new SDKDataGridOptions();
	protected _menuOptions: boolean = false;
	protected _data: any = "";
	protected _columns: SDKDataGridColumn[] = [];
	protected _customFilters: SDKDataGridCustomFilter[] = [];
	protected _settings: SDKDataGridSettings[] = [];
	protected _rows: number = 100;
	protected _page: number = 1;
	protected _selectedPage: string = "";
	protected _total: number = 0;
	protected _isExporting: boolean = false;

	/**************************************************************************
	* Options Variables
	**************************************************************************/
	protected optionTitle: string = "";
	protected dataMode: string = "data";
	protected dataClass: string = "";
	protected columnBadge: string = "0";
	protected filterBadge: string = "0";
	protected sortBadge: string = "0";
	protected formulaBadge: string = "0";
	protected chartBadge: string = "0";
	protected showReset: boolean = false;
	protected showNote: boolean = false;
	protected lockGrid: boolean = false;

	protected datasetTabs: any;

	/**************************************************************************
	* Addon Variables
	*   - option(Inputs/Outputs): Used with the Addon icons.
	*   - window(Inputs/Outputs): Used with the Addon modal.
	**************************************************************************/
	public optionInputs = {};
	public windowInputs = {};

	public optionOutputs = {
		showDataOptionsEvent: (event: any) => this.showDataOptions(event),
	};

	public windowOutputs = {
		closeEvent: () => this.closeDataOptions(),
		applyEvent: (event: any) => this.applyDataOptions(event),
	};

	/**************************************************************************
	* Message Variable
	**************************************************************************/
	protected showMessage: boolean = false;
	protected message: SDKDataGridMessage = new SDKDataGridMessage();

	/**************************************************************************
	* Private Variables
	**************************************************************************/

	/**************************************************************************
	* Component Lifecycle Methods
	**************************************************************************/
	constructor(
		private route: ActivatedRoute
	) { }

	protected ngOnChanges(_args: any) {
		this.showLog("START Method: ngOnChanges >>>");
		// this.showLog(["[Input]", Clone.deepCopy(_args)]);

		// Handles update grid event
		if (_args.updateGrid !== undefined) {
			this.showLog(["updateGrid", _args.updateGrid.currentValue]);

			if (_args.updateGrid.currentValue) {
				if (_args.updateGrid.previousValue === undefined) {
					this.setDatasets();
					this.setActiveDataset(); // MUST be called before setName() - active dataset is used in setting the name.
					this.setName();
					this.setOptions();
					this.setColumns();
					this.setCustomFilters();
					this.setSettings(); // MUST be called after setColumns() and setCustomFilters() in case a setting is set to active.

					setTimeout(() => {
						this.loadData("Initial Load", this._rows, 1);
					}, 10);
				} else {
					this.showLog("!!! Updating Grid !!!");

					this.showInputValues();
					this.setGrid();
					this.setDatasets();
					this.setActiveDataset(); // MUST be called before setName() - active dataset is used in setting the name.
					this.setName();
					this.setOptions();
					this.setColumns();
					this.setCustomFilters();
					this.setSettings(); // MUST be called after setColumns() and setCustomFilters() in case a setting is set to active.
					this.setBadges();
					this.setData();
					this.setRows();
					this.setPage();
					this.setTotal();
					this.setSelectedPage();

					this.showLog("!!! Grid Updated !!!");

					this.showGrid = true;

					setTimeout(() => {
						this.editRecordIndexChange.emit(undefined);
					}, 10);
				}

				setTimeout(() => {
					this.updateGridChange.emit(false);
				}, 10);
			}
		}

		// Handles edit mode event
		if (_args.editRecordIndex) {
			this.setEditRecordIndex();
		}

		// Handles "all data" export
		if (_args.dataExport !== undefined && _args.dataExport.currentValue !== undefined && _args.dataExport.currentValue !== "") {
			this.exportData(true, _args.dataExport.currentValue);
		}

		this.showLog(">>> END Method: ngOnChanges");
	}

	/**************************************************************************
	* Change Methods
	**************************************************************************/
	private showInputValues() {
		this.showLog("*** START: Input Values ***");
		this.showLog(["editRecordIndex:", this.editRecordIndex]);
		this.showLog(["datasets:", this.datasets]);
		this.showLog(["activeDataset:", this.activeDataset]);
		this.showLog(["name:", this.name]);
		this.showLog(["title:", this.title]);
		this.showLog(["titleStyle:", this.titleStyle]);
		this.showLog(["settings:", Clone.deepCopy(this.settings)]);
		this.showLog(["options:", Clone.deepCopy(this.options)]);
		this.showLog(["data:", Clone.deepCopy(this.data)]);
		this.showLog(["columns:", Clone.deepCopy(this.columns)]);
		this.showLog(["customFilters:", Clone.deepCopy(this.customFilters)]);
		this.showLog(["rowValues:", this.rowValues]);
		this.showLog(["defaultRows:", this.defaultRows]);
		this.showLog(["rows:", this.rows]);
		this.showLog(["page:", this.page]);
		this.showLog(["total:", this.total]);
		this.showLog("*** END: Input Values ***");
	}

	private setGrid() {
		this.showLog("START Method: setGrid >>>");
		this.showLog(["Set Styles", this.fontFamily]);

		if (this.sdkdatagrid) {
			if (this.fontFamily !== "") {
				this.sdkdatagrid.nativeElement.style.setProperty("--font-family", this.fontFamily);
			}
		}

		if (this.sdkdatagridcontent) {
			this.sdkdatagridcontent.nativeElement.scrollTop = 0;
		}
		this.showLog(">>> END Method: setGrid");
	}

	private setDatasets() {
		this.showLog("START Method: setDatasets >>>");
		this.showLog(["[Input]", this.datasets]);

		this._datasets = this.datasets ?? [];

		this.datasetTabs = [];

		this._datasets.forEach((dataset: string) => {
			this.datasetTabs.push({
				title: dataset,
				type: <any>null,
				inputs: {},
				outputs: {}
			});
		});

		this.showLog(["[Output]", this._datasets]);
		this.showLog(">>> END Method: setDatasets");
	}

	private setActiveDataset() {
		this.showLog("START Method: setActiveDataset >>>");
		this.showLog(["[Input]", this.activeDataset]);

		this._activeDataset = this.activeDataset !== "" ? this.activeDataset : this._datasets.length > 0 ? this._datasets[0] : "";

		// Initialize then let sdk-select handle value moving forward.
		if (this._selectedDataset === "") {
			this._selectedDataset = this._activeDataset;
		}

		this.showLog(["[Output]", this._activeDataset]);
		this.showLog(">>> END Method: setActiveDataset");
	}

	private setName() {
		this.showLog("START Method: setName >>>");
		this.showLog(["[Input]", this.name]);

		if (this.route.url) {
			this.route.url.subscribe(([url]) => {
				if (url) {
					const { path } = url;

					if (url.path) this._name = `${url.path}_${this.route.component?.name}_${this.name}_${this._activeDataset}`;
				}
			});
		}

		if (this._name === "") {
			this._name = `${this.route.component?.name}_${this.name}_${this._activeDataset}`;
		}

		this._name = this._name.replaceAll(/[^a-zA-Z0-9]/g, "_");

		this.showLog(["[Output]", this._name]);
		this.showLog(">>> END Method: setName");
	}

	private setOptions() {
		this.showLog("START Method: setOptions >>>");
		this.showLog(["[Input]", Clone.deepCopy(this.options)]);

		this._options = new SDKDataGridOptions();

		if (this.options) {
			Object.getOwnPropertyNames(this._options).forEach((prop: any) => {
				const descriptor = Object.getOwnPropertyDescriptor(this.options, prop);

				if (descriptor) {
					(this._options as { [key: string]: any })[prop] = (this.options as { [key: string]: any })[prop];
				}
			});
		}

		if (this._options.minimizeOptions !== undefined) {
			this._menuOptions = false;
		}

		this.showLog(["[Output]", Clone.deepCopy(this._options)]);
		this.showLog(">>> END Method: setOptions");
	}

	private setColumns() {
		this.showLog("START Method: setColumns >>>");
		this.showLog(["[Input]", Clone.deepCopy(this.columns)]);

		this._columns = [];

		let _tmp = Clone.deepCopy(this.columns);

		if (!_tmp || _tmp.length === 0) {
			_tmp = [];

			if (this.data && this.data.length > 0) {
				let data: any = Clone.deepCopy(this.data[0]);

				for (let key in data) {
					_tmp.push({ Name: key.toString() });
				}
			}
		}

		_tmp.forEach((column: SDKDataGridColumn, index: number) => {
			this.initializeColumn(new SDKDataGridColumn(), column, index);
		});

		// Load all left-side action columns.
		_tmp.filter((column: SDKDataGridColumn) => column.isAction && column.actionSide === "left").forEach((column: SDKDataGridColumn) => {
			this._columns.push({ ...column });
		});

		// Load all non-action columns.
		_tmp.filter((column: SDKDataGridColumn) => !column.isAction).forEach((column: SDKDataGridColumn) => {
			this._columns.push({ ...column });
		});

		// Load all right-side action columns.
		_tmp.filter((column: SDKDataGridColumn) => column.isAction && column.actionSide === "right").forEach((column: SDKDataGridColumn) => {
			this._columns.push({ ...column });
		});

		this.showLog(["[Output]", Clone.deepCopy(this._columns)]);
		this.showLog(">>> END Method: setColumns");
	}

	private setCustomFilters() {
		this.showLog("START Method: setCustomFilters >>>");
		this.showLog(["[Input]", Clone.deepCopy(this.customFilters)]);

		this._customFilters = [];

		let _tmp = Clone.deepCopy(this.customFilters);

		if (_tmp && _tmp.length > 0) {
			_tmp.forEach((filter: SDKDataGridCustomFilter, index: number) => {
				this.initializeCustomFilter(new SDKDataGridCustomFilter(), filter, index);

				this._customFilters.push({ ...filter });
			});
		}

		this.showLog(["[Output]", Clone.deepCopy(this._customFilters)]);
		this.showLog(">>> END Method: setCustomFilters");
	}

	private setSettings() {
		this.showLog("START Method: setSettings >>>");

		if (!this._options.settings) {
			this.settings = [];

			// REMOVES SETTINGS FROM "GRID" STORAGE IF FORCED
			if (this.clearSettingsCache) {
				this.showLog("!!! Clear Settings Cache !!!");

				let storageItemName = `SDK-DATAGRID_${this._name}`;

				localStorage.removeItem(storageItemName);
				sessionStorage.removeItem(storageItemName);

				setTimeout(() => {
					this.clearSettingsCacheChange.emit(false);
				}, 10);
			} else {
				// LOAD SETTINGS FROM "GRID" STORAGE
				let storageItemName = `SDK-DATAGRID_${this._name}`;
				let storage = this._options.settingsStorage === StorageType.Local ? localStorage.getItem(storageItemName) : sessionStorage.getItem(storageItemName);

				if (storage) {
					this.settings = JSON.parse(storage);
				}
			}
		} else {
			let storageItemName = `SDK-DATAGRID_${this._name}`;

			localStorage.removeItem(storageItemName);
			sessionStorage.removeItem(storageItemName);
		}

		this.showLog(["[Input]", Clone.deepCopy(this.settings)]);

		this._settings = [];

		let _tmp = Clone.deepCopy(this.settings);

		_tmp.forEach((setting: SDKDataGridSettings) => {
			let _setting: SDKDataGridSettings = new SDKDataGridSettings();

			Object.getOwnPropertyNames(_setting).forEach((prop: any) => {
				const descriptor = Object.getOwnPropertyDescriptor(setting, prop);

				if (!descriptor) {
					(setting as { [key: string]: any })[prop] = (_setting as { [key: string]: any })[prop];
				}
			});

			this._settings.push({ ...setting });
		});

		let ndx = this._settings.findIndex((setting: SDKDataGridSettings) => setting.Active);

		if (ndx > -1) {
			this._columns = Clone.deepCopy(this._settings[ndx].Columns);

			this._columns.forEach((column: SDKDataGridColumn, index: number) => {
				let columnNdx = this.columns.findIndex((c: SDKDataGridColumn) => c.Name === column.Name);

				if (columnNdx > -1) {
					this.initializeColumn(this.columns[columnNdx], column, index);
				}
			});

			this._customFilters = Clone.deepCopy(this._settings[ndx].CustomFilters);

			this._customFilters.forEach((filter: SDKDataGridCustomFilter, index: number) => {
				let filternNdx = this.customFilters.findIndex((f: SDKDataGridCustomFilter) => f.Name === filter.Name);

				if (filternNdx > -1) {
					this.initializeCustomFilter(this.customFilters[filternNdx], filter, index);
				}
			});
		}

		this.showLog(["[Output]", Clone.deepCopy(this._settings)]);
		this.showLog(">>> END Method: setSettings");
	}

	private setBadges() {
		this.showLog("START Method: setBadges >>>");

		this.showNote = this._columns.filter((column: SDKDataGridColumn) => column.FriendlyName !== "").length > 0 ? true : false;

		let columnCount: number = this._columns.filter((column: SDKDataGridColumn, index: number) => index !== column._original.index).length > 0 ? 1 : 0;
		columnCount += this._columns.filter((column: SDKDataGridColumn) => column.isVisible !== column._original.isVisible || column.FriendlyName !== column._original.FriendlyName).length;
		this.columnBadge = columnCount.toString();

		let filterCount: number = this._columns.filter((column: SDKDataGridColumn) => column.Filter).length;
		filterCount += this._customFilters.filter((filter: SDKDataGridCustomFilter) => filter.Filter).length;
		this.filterBadge = filterCount.toString();

		this.sortBadge = this._columns.filter((column: SDKDataGridColumn) => column.Sort).length.toString();

		let formulaCount = 0;
		this._columns.filter((column: SDKDataGridColumn) => column.Formulas).forEach((column: SDKDataGridColumn) => {
			formulaCount += column.Formulas.length;
		})
		this.formulaBadge = formulaCount.toString();

		if (this.columnBadge !== "0" || this.filterBadge !== "0" || this.sortBadge !== "0" || this.formulaBadge !== "0") {
			this.showReset = true;
		} else {
			this.showReset = false;
		}

		this.showLog(">>> END Method: setBadges");
	}

	private setData() {
		this.showLog("START Method: setData >>>");
		this.showLog(["[Input]", Clone.deepCopy(this.data)]);

		this._data = Clone.deepCopy(this.data);

		this.showLog(["[Output]", Clone.deepCopy(this._data)]);
		this.showLog(">>> END Method: setData");
	}

	private setRows() {
		this.showLog("START Method: setRows >>>");
		this.showLog(["[Input]", this.rows, "(Default)", this.defaultRows]);

		this._rows = this.rows ?? this.defaultRows;

		this.showLog(["[Output]", this._rows]);
		this.showLog(">>> END Method: setRows");
	}

	private setPage() {
		this.showLog("START Method: setPage >>>");
		this.showLog(["[Input]", this.page]);

		this._page = this.page ?? 1;

		this.showLog(["[Output]", this._page]);
		this.showLog(">>> END Method: setPage");
	}

	private setTotal() {
		this.showLog("START Method: setTotal >>>");
		this.showLog(["[Input]", this.total]);

		if (this.total === undefined) {
			this._total = this._data.length;
		} else {
			this._total = this.total;
		}

		this.showLog(["[Output]", this._total]);
		this.showLog(">>> END Method: setTotal");
	}

	private setSelectedPage() {
		this.showLog("START Method: setSelectedPage >>>");

		if (this.totalPages.length === 0 || this.totalPages.length !== Math.ceil(this._total / this._rows)) {
			this.showLog("Updating pages...");

			this.totalPages = new Array(0);

			for (let p = 0; p < Math.ceil(this._total / this._rows); p++) {
				let start: number = (p * this._rows) + 1;
				let end: number = (start + this._rows) - 1;

				if (end > this._total) end = this._total;

				this.totalPages.push(start.toString() + " - " + end.toString());
			}
		}

		let rowStart: number = ((this._page - 1) * this._rows) + 1;
		let rowEnd: number = (rowStart + this._rows) - 1;

		if (rowEnd > this._total) rowEnd = this._total;

		this._selectedPage = rowStart.toString() + " - " + rowEnd.toString();

		this.showLog(["[Output]", this._selectedPage]);
		this.showLog(">>> END Method: setSelectedPage");
	}

	private setEditRecordIndex() {
		this.showLog("START Method: setEditRecordIndex >>>");
		this.showLog(["[Input]", this.editRecordIndex]);

		this._editRecordIndex = this.editRecordIndex;

		this.showLog(["[Output]", this._editRecordIndex]);
		this.showLog(">>> END Method: setEditRecordIndex");
	}

	/**************************************************************************
	* Data Methods
	**************************************************************************/
	protected loadData(action: string, rows: number, page: number) {
		this.showLog("START Method: loadData >>>");
		this.showLog(["action", action, "rows", rows, "page", page]);

		this.editRecordIndexChange.emit(undefined);
		this.columnsChange.emit(this._columns);
		this.customFiltersChange.emit(this._customFilters);

		this.setAddonInputs();

		this.loadDataEvent.emit({ action: action, rows: rows, page: page });

		this.showLog(">>> END Method: loadData");
	}

	protected selectDataset(event: any) {
		this.showLog("START Method: selectDataset (Dropdown) >>>");
		this.showLog(["[Input]", event[0]]);

		this.activeDatasetChange.emit(event[0]);

		setTimeout(() => {
			this.setActiveDataset(); // MUST be called before setName() - active dataset is used in setting the name.
			this.setName();
			this.setSettings(); // MUST be called in case columns are saved.

			this.loadData(`Dataset Change`, this._rows, 1);
		}, 10);

		this.showLog(">>> END Method: selectDataset (Dropdown)");
	}

	protected datasetChanged(event: any) {
		this.showLog("START Method: datasetChanged (Tab) >>>");
		this.showLog(["[Input]", event]);

		if (event.from) {
			this.activeDatasetChange.emit(event.to.title);

			setTimeout(() => {
				this.setActiveDataset(); // MUST be called before setName() - active dataset is used in setting the name.
				this.setName();
				this.setSettings(); // MUST be called in case columns are saved.

				this.loadData(`Dataset Change`, this._rows, 1);
			}, 10);
		}

		this.showLog(">>> END Method: datasetChanged (Tab)");
	}

	protected saveData() {
		this.showLog("START Method: saveData >>>");

		if (this.missingData()) {
			this.message = {
				Title: "Missing Data",
				Text: "You are missing some required data.",
				OKText: "OK",
				OK: () => {
					this.showMessage = false;
				}
			};
		} else {
			this.message = {
				Title: "Save Changes",
				Text: "Are you sure?",
				OKText: "YES",
				CancelText: "NO",
				OK: () => {
					this.showMessage = false;
					this.editGrid = false;
					this.editRecords = false;

					if (this.saveGridDataEvent.observed) {
						this.saveGridDataEvent.emit(this._data);
					}

					this.editRecordIndexChange.emit(undefined);
					this.updateGridChange.emit(false);
				},
				Cancel: () => {
					this.showMessage = false;
				}
			};
		}

		this.showMessage = true;

		this.showLog(">>> END Method: saveData");
	}

	protected deleteData() {
		this.showLog("START Method: deleteData >>>");
		this.showLog(["records", this.deleteRecords]);

		if (this.deleteRecords.length > 0) {
			this.editRecords = this.hasDataChanged();

			if (this.editRecords) {
				this.message = {
					Title: "Save Changes",
					Text: "You've made changes to the data.<br /><br />Would you like to save them before continuing?",
					OKText: "YES",
					CancelText: "NO",
					OK: () => {
						this.editRecords = false;

						if (this.saveGridDataEvent.observed) {
							this.saveGridDataEvent.emit(this._data);
						}

						this.editRecordIndexChange.emit(undefined);
						this.updateGridChange.emit(false);

						this.deleteData();
					},
					Cancel: () => {
						this.editRecords = false;

						this._data = Clone.deepCopy(this.data);

						this.deleteData();
					}
				};
			} else {
				this.message = {
					Title: "Delete record(s)",
					Text: "Are you sure?",
					OKText: "YES",
					CancelText: "NO",
					OK: () => {
						this.showMessage = false;

						if (this.deleteGridDataEvent.observed) {
							this.deleteGridDataEvent.emit(this.deleteRecords);
						}

						this.deleteRecords = [];
					},
					Cancel: () => {
						this.showMessage = false;
					}
				};

			}

			this.showMessage = true;
		}

		this.showLog(">>> END Method: deleteData");
	}

	protected selectRecord(index: number) {
		let ndx = this.deleteRecords.findIndex((record: number) => record === index);

		if (ndx > -1) {
			this.deleteRecords.splice(ndx, 1);
		} else {
			this.deleteRecords.push(index);
		}
	}

	protected isRecordSelected(index: number) {
		let ndx = this.deleteRecords.findIndex((record: number) => record === index);

		if (ndx > -1) {
			return true;
		} else {
			return false;
		}
	}

	protected cancelEdit() {
		this.showLog("START Method: cancelEdit >>>");

		this.editRecords = this.hasDataChanged();

		if (this.editRecords) {
			this.message = {
				Title: "Cancel Changes",
				Text: "You've made changes to the data.<br /><br />Are you sure you want to cancel without saving them?",
				OKText: "YES",
				CancelText: "NO",
				OK: () => {
					this.showMessage = false;
					this.editGrid = false;
					this.editRecords = false;
					this.deleteRecords = [];

					this.loadData("Cancel Changes", this._rows, this._page);
				},
				Cancel: () => {
					this.showMessage = false;
				}
			};

			this.showMessage = true;
		} else {
			this.editGrid = false;
			this.editRecords = false;
			this.deleteRecords = [];
		}

		this.showLog(">>> END Method: cancelEdit");
	}

	private hasDataChanged() {
		return !(
			this.data.length === this._data.length &&
			this.data.every((element_1: any) =>
				this._data.some((element_2: any) =>
					Object.keys(element_1).every((key) => element_1[key] === element_2[key])
				)
			)
		);
	}

	private missingData() {
		return this._data.some((record: any) => {
			return this._columns.filter((column: SDKDataGridColumn) => column.required)
				.some((column: SDKDataGridColumn) => record[column.Name] === "");
		});
	}

	/**************************************************************************
	* Options Methods
	**************************************************************************/
	protected showDataOptions(type: string) {
		this.showLog("START Method: showDataOptions >>>");
		this.showLog(["type", type]);

		this.lockGrid = true;

		this.dataClass = this._options.panelOverlay ? "overlay" : "expand";
		this.dataMode = "data";

		this.optionTitle = type;

		if (type === "export") {
			this.dataExportChange.emit(""); // Clears out value
		}

		this.setAddonInputs();

		this.showLog(">>> END Method: showDataOptions");
	}

	protected toggleDataMode(type: string, autoClose: boolean = false) {
		// this.dataMode = type;

		// if (this.dataMode === "data" && this.optionTitle === "Chart") {
		//     this.dataClass = "";
		//     this.optionTitle = "";
		// }
		// if (this.dataMode === "chart") {
		//     if (this.chart && this.chart !== "") {
		//         this.applyChartOptions(this.chart, autoClose);
		//     }
		// }
	}

	protected closeDataOptions() {
		this.showLog("START Method: closeDataOptions >>>");

		this.optionTitle = "";
		this.dataClass = "";
		this.lockGrid = false;

		this.setAddonInputs();

		this.showLog(">>> END Method: closeDataOptions");
	}

	protected resetAllOptions() {
		this.showLog("START Method: resetAllOptions >>>");

		this.message = {
			Title: "Reset ALL Options",
			Text: "Are you sure?",
			OKText: "YES",
			CancelText: "NO",
			OK: () => {
				this.showMessage = false;

				this.dataMode = "data";
				this.optionTitle = "";
				this.dataClass = "";

				this._columns.sort((a: SDKDataGridColumn, b: SDKDataGridColumn) => (a._original.index > b._original.index) ? 1 : -1);

				this._columns.forEach((column: SDKDataGridColumn) => {
					column.FriendlyName = column._original.FriendlyName;
					column.isVisible = column._original.isVisible;
					column.Sort = column._original.Sort;
					column.Filter = column._original.Filter;
					column.Formulas = column._original.Formulas;
				});

				this._customFilters.forEach((filter: SDKDataGridCustomFilter) => {
					filter.Filter = filter._original.Filter;
				});

				if (this._options.settings) {
					this.saveSettings(this._settings, true);
				} else {
					// REMOVE SETTINGS FROM "GRID" STORAGE
					let storageItemName = `SDK-DATAGRID_${this._name}`;

					if (this._options.settingsStorage === StorageType.Local) {
						localStorage.removeItem(storageItemName);
					} else {
						sessionStorage.removeItem(storageItemName);
					}

					this.settings = [];
				}

				this.showReset = false;

				this.loadData("Reset ALL Options", this._rows, 1);
			},
			Cancel: () => {
				this.showMessage = false;
			}
		};

		this.showMessage = true;

		this.showLog(["columns", this._columns, "customFilters", this._customFilters]);
		this.showLog(">>> END Method: resetAllOptions");
	}

	protected applyOptions(event: any) {
		this.showLog("START Method: applyOptions >>>");
		this.showLog(["[Input]", event]);

		let action: string = "Options";

		if (event?.option) action = event.option;
		if (event?.columns) this._columns = event.columns;
		if (event?.customFilters) this._customFilters = event.customFilters;

		if (this._options.settings) {
			this.saveSettings(this._settings, true);
		} else {
			// ADD SETTINGS TO "GRID" STORAGE
			let storageItemName = `SDK-DATAGRID_${this._name}`;
			let settings = [{
				Name: "[sdk-datagrid Settings]",
				Columns: this._columns,
				CustomFilters: this._customFilters,
				Active: true
			}]

			if (this._options.settingsStorage === StorageType.Local) {
				localStorage.setItem(storageItemName, JSON.stringify(settings));
			} else {
				sessionStorage.setItem(storageItemName, JSON.stringify(settings));
			}
		}

		let rows = this._rows;
		let page = this._page;

		if (this.optionTitle !== "columns") {
			page = 1;
		}

		if (this._options.autoClosePanel) {
			this.closeDataOptions();
		}

		this.loadData(`Apply ${action}`, rows, page);

		this.showLog(">>> END Method: applyOptions");
	}

	/**************************************************************************
	* Addon Methods
	**************************************************************************/
	public applyDataOptions(event: any) {
		this.showLog("START Method: applyDataOptions >>>");
		this.showLog(["option", this.optionTitle]);

		if (event?.columns) this._columns = event.columns;
		if (event?.customFilters) this._customFilters = event.customFilters;

		this.editRecordIndexChange.emit(undefined);
		this.columnsChange.emit(this._columns);
		this.customFiltersChange.emit(this._customFilters);

		this.loadDataEvent.emit({ action: this.optionTitle, rows: this._rows, page: 1, addon: event });

		this.setAddonInputs();

		this.showLog(">>> END Method: applyDataOptions");
	}

	private setAddonInputs() {
		this.optionInputs = {
			optionTitle: this.optionTitle,
		};

		this.windowInputs = {
			dataClass: this.dataClass,
			optionTitle: this.optionTitle,
			columns: this._columns ?? [],
			customFilters: this._customFilters ?? []
		};
	}

	/**************************************************************************
	* Settings Methods
	**************************************************************************/
	protected applySettingsOptions(event: any) {
		this.showLog("START Method: applySettingsOptions >>>");
		this.showLog(["[Input]", event]);

		if (event?.columns) {
			this._columns = Clone.deepCopy(event.columns);

			this._columns.forEach((column: SDKDataGridColumn, index: number) => {
				let columnNdx = this.columns.findIndex((c: SDKDataGridColumn) => c.Name === column.Name);

				if (columnNdx > -1) {
					this.initializeColumn(this.columns[columnNdx], column, index);
				}
			});
		}

		if (event?.customFilters) {
			this._customFilters = Clone.deepCopy(event.customFilters);

			this._customFilters.forEach((filter: SDKDataGridCustomFilter, index: number) => {
				let filternNdx = this.customFilters.findIndex((f: SDKDataGridCustomFilter) => f.Name === filter.Name);

				if (filternNdx > -1) {
					this.initializeCustomFilter(this.customFilters[filternNdx], filter, index);
				}
			});
		}

		let rows = this._rows;
		let page = 1;

		if (this._options.autoClosePanel) {
			this.closeDataOptions();
		}

		this.loadData("Apply Settings", rows, page);

		this.showLog(">>> END Method: applySettingsOptions");
	}

	protected saveSettings(event: any, resetActive: boolean = false) {
		this.showLog("START Method: saveSettings >>>");
		this.showLog(["[Input]", event]);

		let settings = event.filter((setting: SDKDataGridSettings) => setting.Name !== "[Current Settings]");

		if (resetActive) {
			settings.forEach((setting: SDKDataGridSettings) => {
				setting.Active = false;
			});
		}

		this._settings = settings;

		this.savedSettingsEvent.emit(this._settings);

		this.showLog(">>> END Method: saveSettings");
	}

	/**************************************************************************
	* Export Methods
	**************************************************************************/
	protected applyExportOptions(event: any) {
		this.showLog("START Method: applyExportOptions >>>");
		this.showLog(["[Input]", event]);

		this._isExporting = true;

		setTimeout(() => {
			let includeAllData = event;

			if (includeAllData) {
				this.loadDataEvent.emit({ action: "Export Data" });
			} else {
				this.exportData(false, Clone.deepCopy(this._data));
			}
		}, 100);

		this.showLog(">>> END Method: applyExportOptions");
	}

	private exportData(includeAllData: boolean, data: any) {
		this.showLog("START Method: exportData >>>");
		this.showLog(["includeAllData", includeAllData]);

		if (!includeAllData) {
			data = this.adjustExportColumns(data);
		}

		let dataArray: any[] = JSON.parse(JSON.stringify(data));

		let link = document.createElement("a");

		link.href = 'data:text/csv;charset=utf-8,' + this.convertToCSV(dataArray);
		link.download = "export.csv";
		link.click();

		this._isExporting = false;

		if (this._options.autoClosePanel) {
			this.closeDataOptions();
		}

		this.showLog(">>> END Method: exportData");
	}

	private adjustExportColumns(tmp: any) {
		let rows: any = [];

		tmp.forEach((row: any) => {
			let columns: any = {};

			this._columns.forEach((column: any) => {
				if (column.isVisible && !column.isAction) {
					if (column.FriendlyName !== "") {
						columns[column.FriendlyName] = row[column.Name];
					} else if (column.DisplayName !== "") {
						columns[column.DisplayName] = row[column.Name];
					} else {
						columns[column.Name] = row[column.Name];
					}
				}
			});

			rows.push(columns);
		});

		return rows;
	}

	private convertToCSV(objArray: any) {
		let csv: any = "";

		for (let row = 0; row < objArray.length; row++) {
			let output: any = "";

			// Add column names as first row.
			if (csv === "") {
				for (let column in objArray[row]) {
					if (output !== "") {
						output += ",";
					}

					output += column;
				}

				csv += `${output}\r\n`;
				output = "";
			}

			for (let column in objArray[row]) {
				if (output !== "") {
					output += ",";
				}

				if (objArray[row][column]) {
					let data = `"${objArray[row][column].toString().replace(/"/g, '""')}"`;
					output += data;
				} else {
					output += "";
				}
			}

			csv += `${encodeURIComponent(output)}\r\n`;
		}

		return csv;
	}

	/**************************************************************************
	* Row Methods
	**************************************************************************/
	protected takeAction(args: any) {
		this.showLog("START Method: takeAction >>>");
		this.showLog(["[Input]", args]);

		if (this.selectedRowActionEvent.observed) {
			this.selectedRowActionEvent.emit(args);
		}

		this.showLog(">>> END Method: takeAction");
	}

	// Used to remember where the scrollbar was on click-throughs (drill-downs).
	// protected onScroll(args: any) {
	// 	// if (this.selectedRowActionEvent.observed) {
	// 	//     this._scrollTop = args.target.scrollTop;
	// 	// }
	// }

    /**************************************************************************
    * Expand/Collapse Rows
    **************************************************************************/
    public expandAll() {
        this.setExpandedForAll(true);
    }

    public collapseAll() {
        this.setExpandedForAll(false);
    }

    public toggleExpanded(rowItem: any) {
        rowItem["Expanded"] = !rowItem["Expanded"];
    }

    public isFirstColumn(column: SDKDataGridColumn): boolean {
        let visibleColumns = this._columns.filter(c => c.isVisible);

        return visibleColumns.length > 0 && column.Name === visibleColumns[0].Name;
    }

    private setExpandedForAll(isExpanded: boolean) {
        let dataItems = this._data as unknown as any[];

        dataItems.forEach((item) => {
            item["Expanded"] = isExpanded;
        });
    }

	/**************************************************************************
	* Column Methods
	**************************************************************************/
	protected getColumnName(column: SDKDataGridColumn) {
		let originalName: string | undefined = column.Name;

		if (column.FriendlyName !== "") {
			originalName = `${column.FriendlyName} *`;
		} else if (column.DisplayName !== "") {
			originalName = column.DisplayName;
		}

		return originalName;
	}

	protected getOriginalColumnName(column: SDKDataGridColumn) {
		let originalName = column.Name;

		if (column.DisplayName && column.DisplayName !== "") {
			originalName = column.DisplayName;
		}

		return `Orig. Name:\n${originalName}`;
	}

	protected hasVisibleColumns() {
		return this._columns.filter((column: SDKDataGridColumn) => column.isVisible);
	}

	protected hasFormulas() {
		return this._columns.filter((column: SDKDataGridColumn) => column.Formulas);
	}

	protected isColumnObject(value: any) {
		if (typeof (value) === "object") {
			return true;
		} else {
			return false;
		}
	}

	protected sortColumn(column: SDKDataGridColumn) {
		let ndx = this._columns.findIndex((c: any) => c.Name === column.Name);
		let sort: any = null;

		if (ndx > -1 && !this._columns[ndx].isAction && this._columns[ndx].showSort) {
			sort = this._columns[ndx].Sort;

			if (sort) {
				this._columns[ndx].Sort!.direction = (this._columns[ndx].Sort?.direction === Sorts.ASC) ? Sorts.DESC : Sorts.ASC;
			} else {
				this._columns[ndx].Sort = { direction: Sorts.ASC, position: 0 };
			}

			this.applyOptions({ columns: this._columns });
		}
	}

	/**************************************************************************
	* Page Methods
	**************************************************************************/
	protected getRows(event: any) {
		this.showLog("START Method: getRows >>>");
		this.showLog(["rows", event[0]]);

		this.loadData("Rows/Page Change", parseInt(event[0]), 1);

		this.showLog(">>> END Method: getRows");
	}

	protected getPage(event: any) {
		this.showLog("START Method: getPage >>>");
		this.showLog(["page", Array.isArray(event) ? Math.ceil(parseInt(event[0].split(" - ")[1]) / this._rows) : event]);

		if (Array.isArray(event)) event = Math.ceil(parseInt(event[0].split(" - ")[1]) / this._rows);

		this.loadData("Page Change", this._rows, parseInt(event));

		this.showLog(">>> END Method: getPage");
	}

	/**************************************************************************
	* Util Methods
	**************************************************************************/
	private initializeColumn(baseColumn: SDKDataGridColumn, column: SDKDataGridColumn, index: number) {
		Object.getOwnPropertyNames(baseColumn).forEach((prop: any) => {
			const descriptor = Object.getOwnPropertyDescriptor(column, prop);

			if (!descriptor) {
				(column as { [key: string]: any })[prop] = (baseColumn as { [key: string]: any })[prop];
			}
		});

		// Set original values
		if (!column._original || column._original.index === null) {
			column._original.index = index;
			column._original.FriendlyName = column.FriendlyName;
			column._original.isVisible = column.isVisible;
			column._original.Sort = column.Sort;
			column._original.Filter = column.Filter;
			column._original.Formulas = column.Formulas;
		}
	}

	private initializeCustomFilter(baseFilter: SDKDataGridCustomFilter, filter: SDKDataGridCustomFilter, index: number) {
		Object.getOwnPropertyNames(baseFilter).forEach((prop: any) => {
			const descriptor = Object.getOwnPropertyDescriptor(filter, prop);

			if (!descriptor) {
				(filter as { [key: string]: any })[prop] = (baseFilter as { [key: string]: any })[prop];
			}
		});

		// Set original values
		if (filter._original.index === null) {
			filter._original.index = index;
			filter._original.Filter = filter.Filter;
		}
	}

	private showLog(args: any) {
		if (this.isDebug) {
			if (Array.isArray(args)) {
				console.log(...args);
			} else {
				console.log(args);
			}
		}
	}
}
