import { Component, ElementRef, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface SDKTab {
    title: string; // Name on the tab.
    type?: Type<any>; // The component to display when the tab is selected.
    inputs?: any; // Input properties for the component displayed.
    outputs?: any // Output properties for the component displayed.
}

@Component({
    selector: 'sdk-tabs',
    templateUrl: './sdk-tabs.component.html',
    styleUrls: ['./sdk-tabs.component.scss']
})

export class SDKTabsComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() tabs: SDKTab[] = []; // List of tabs.
    @Input() activeTab: SDKTab | undefined; // Set the current active tab. This is used to load a tab from a share.
    @Input() fontFamily: string = ""; // Font to use for the tab titles.
    @Input() fontColor: string = ""; // Color to use for the tab titles.
    @Input() tabColor: string = ""; // Color of the tabs.
    @Input() tabBorderColor: string = ""; // Border color of the tabs.
    @Input() activeTabColor: string = ""; // Active tab color.
    @Input() activeFontColor: string = ""; // Active tab font color.
    @Input() height: string = ""; // Height of tabs. Default is auto.
    @Input() share: boolean = false; // Enable share icon.
    @Input() urlParams: string = ""; // Additional Url parameters for sharing.
    @Output() tabChangedEvent: EventEmitter<{ from: any, to: any }> = new EventEmitter(); // Callback when active tab changes.

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    @ViewChild('sdktabs') sdktabs!: ElementRef<any>;
    @ViewChild('sdktabinfo') sdktabinfo!: ElementRef<any>;

    protected sharedUrl: string = "";
    protected message: string = "";

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    //******************************************************************************
    //  Component Life-cycle Methods
    //******************************************************************************
    protected ngOnInit() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.tab) {
                this.activeTab = this.tabs.find((tab: SDKTab) => tab.title === params.tab);
            }
        });

        if (this.tabs.length > 0) {
            if (!this.activeTab) {
                this.setActiveTab(this.tabs[0]);
            } else {
                this.setSharedUrl();
            }
        }
    }

    protected ngAfterViewInit() {
        setTimeout(() => {
            if (this.sdktabs) {
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-font-family", this.fontFamily);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-font-color", this.fontColor);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-color", this.tabColor);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-border-color", this.tabBorderColor);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-active-color", this.activeTabColor);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-active-font-color", this.activeFontColor);
                this.sdktabs.nativeElement.style.setProperty("--sdk-tabs-height", this.height);
            }
        }, 1);
    }

    //*************************************************************************
    //  Protected Methods
    //*************************************************************************
    protected setActiveTab(tab: any) {
        if (tab != this.activeTab && this.tabChangedEvent.observed) {
            this.tabChangedEvent.emit({ from: this.activeTab, to: tab });
        }

        this.activeTab = tab;

        this.setSharedUrl();

        setTimeout(() => {
            if (this.sdktabinfo) {
                this.sdktabinfo.nativeElement.scrollTop = 0;
            }
		}, 10);
    }

    protected copied() {
        this.message = "Link has been Copied!";
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private setSharedUrl() {
        this.sharedUrl = `${window.location.href.split('?')[0]}?tab=${this.activeTab!.title}`;

        if (this.urlParams !== "") {
            this.sharedUrl += `&${this.urlParams}`;
        }
    }
}
