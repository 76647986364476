import { FilterType } from "../enums/filters";
import { SDKDataGridFilter } from "./datagrid-filter";

export class SDKDataGridCustomFilter {
    public Name: string = ""; // Unique filter name.
    public DisplayName?: string = "";  // Name to display to user.
    public Type?: FilterType = FilterType.Textbox; // Type of filter (Textbox, TextboxOnly, Checkbox, Dropdown).
    public notes?: string = "";

    // START: sdk-select properties for Dropdown filters.
    public FilterMultiSelect?: boolean = false; // Allows multiple filter values to be selected.
    public FilterTypes?: any[] = []; // Allows you to define specific filter types. NOTE: If FilterMultiSelect = true, this property is forced to [Filters.Equals, Filters.NotEquals].
    public FilterValues?: any[] = []; // Allows you to define specific filter values.
    public Filter?: SDKDataGridFilter | null = null; // Values set for filter.
    public FilterValueDefault?: any; // Default value for Dropdown type.
    public setFilterValues?: () => Promise<any[]>; // Optional callback method to load filter values.
    // END: sdk-select properties for Dropdown filters.

    public _original?: any = {
        index: null,
        Filter: null
    };
}
