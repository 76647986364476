<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    <div class="data">
        <div>The TABS component is an easy way to organize and display infomation in your engagement layer. It allows you to minimize cognitive overload on the user, which is a common problem with today's applications.</div>
        <div>Cognitive overload occurs when the combination of intrinsic, extraneous, and germane loads becomes overwhelming for the viewer. Even the most intelligent person can only process so much information at once.</div>
        <ul>
            <li><strong>Intrinsic</strong> - How complex the task is.</li>
            <li><strong>Extraneous</strong> - Distractions that increase load.</li>
            <li><strong>Germane</strong> - Linking new information with the already stored in the long term memory.</li>
        </ul>
    </div>
</div>
