import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'core-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    @Input() version: any = "0.0.0";
	@Output() setLayoutCssClass = new EventEmitter();

	public date = new Date();
}
