import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-message',
	templateUrl: './sdk-message.component.html',
	styleUrls: ['./sdk-message.component.scss']
})

export class SDKMessageComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
	@Input() message: string = "";
	@Input() height: string = "";
	@Input() style: string = "";
	@Input() timer: number = 5;
	@Output() messageChange: EventEmitter<any> = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
	@ViewChild('sdkmessage') sdkmessage!: ElementRef<any>;

	protected showMessage: boolean = false;
	protected _message: string = "";

	private _timer: any;

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
	protected ngOnChanges(_args: any): void {
		if (_args.message) {
			this._message = _args.message.currentValue;

			if (this._message && this._message !== "") {
				this.stopTimer();
				this.showMessage = true;
				this.startTimer();
			} else {
				this.showMessage = false;
				this._message = "";
				this.stopTimer();
			}
		}
	}

	protected ngAfterViewInit() {
		setTimeout(() => {
			if(this.sdkmessage) {
				if (this.height !== "") {
					this.sdkmessage.nativeElement.style.setProperty("--message-height", this.height);
					this.sdkmessage.nativeElement.style.height = this.height;
				}

				if (!this.sdkmessage.nativeElement.style.height) {
					this.sdkmessage.nativeElement.style.height = "35px";
				}

				let messageHeight = parseInt(this.sdkmessage.nativeElement.style.height) * -1;

				this.sdkmessage.nativeElement.style.setProperty("--message-top", `${messageHeight}px`);
			}
		}, 1);
	}

    /**************************************************************************
    * Protected Methods
    **************************************************************************/
	protected startTimer() {
		this._timer = setInterval(() => {
			this.clearMessage();
		}, (this.timer * 1000));
	}

	protected stopTimer() {
		clearInterval(this._timer);
	}

	protected clearMessage() {
		this.stopTimer();

		this._message = "";
		this.messageChange.emit();
	}
}
