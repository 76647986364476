import { Component, Input } from '@angular/core';

@Component({
	selector: 'sdk-loading',
	templateUrl: './sdk-loading.component.html',
	styleUrls: ['./sdk-loading.component.scss']
})

export class SDKLoadingComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
	@Input() isLoading: boolean = false;
	@Input() showEllipsis: boolean = false;
	@Input() style: string = "";
}
