import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom, timeout } from 'rxjs';

import { AppConfigService } from '~/core/services/appconfig.service';
import { AppConfig } from '~/core/models/appconfig';

const TIMEOUT: number = 60000;

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private config: AppConfig;
    private apiHeader: HttpHeaders = new HttpHeaders({ "accept": "text/plain", "Content-Type": "application/json" });

    constructor(
        private http: HttpClient,
        private appconfigService: AppConfigService
    ) {
        this.config = this.appconfigService.getConfig();
    }

    public Get<T>(endpoint: string, headers: HttpHeaders = this.apiHeader, callTimeout: number = TIMEOUT): Observable<T> {
        return this.http.get<T>(`${this.config.apiUrl}/${endpoint}`, { headers: headers }).pipe(timeout(callTimeout));
    }

    public Post<T>(endpoint: string, body?: any, headers: HttpHeaders = this.apiHeader, callTimeout: number = TIMEOUT): Observable<T> {
        return this.http.post<T>(`${this.config.apiUrl}/${endpoint}`, body, { headers: headers }).pipe(timeout(callTimeout));
    }

    public async GetAsset(file: string) {
        let results: any = null;

        try {
            let stream$ = this.http.get(`assets/${file}`);

            results = await lastValueFrom(stream$);
        } catch (error: any) {
            throw error;
        }

        return results;
    }

    public GetExternal<T>(url: string, callTimeout: number = TIMEOUT): Observable<T> {
        return this.http.get<T>(url).pipe(timeout(callTimeout));
    }
}
