<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Columns</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <div class="all-select">
            <input type="checkbox" class="select" [checked]="allVisible" (change)="toggleAllColumns($event)" />
            <span>All</span>
        </div>
        <button class="sdk-button button-right" (click)="groupVisibleColumns()">Group Visible</button>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div class="box" *ngFor="let column of columnList" cdkDragBoundary=".list" cdkDrag>
            <div class="select">
                <input type="checkbox" [checked]="column.isVisible" (change)="toggleColumn($event, column)" />
            </div>
            <div class="name">
                <input (input)="setFriendlyName($event, column);" [value]="getColumnName(column)" />
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="sdk-button delete" (click)="reset()">Reset</button>
        <button class="sdk-button" (click)="apply()">Apply</button>
    </div>
</div>

<!-- Lock -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
