<div #sdktabs *ngIf="tabs.length > 0" class="sdk-tabs">
    <div class="tabs">
        <div *ngFor="let tab of tabs" class="tab" (click)="setActiveTab(tab)" [ngClass]="{'active': activeTab && activeTab.title === tab.title}">
            <div class="title">{{ tab.title }}</div>
            <div *ngIf="share && activeTab && activeTab.title === tab.title" title="Share Information" class="sdk-icon" [cdkCopyToClipboard]="sharedUrl" [cdkCopyToClipboardAttempts]="2" (click)="copied()">share</div>
        </div>
        <div class="tab empty"></div>
        <sdk-message [(message)]="message" height="100%"></sdk-message>
    </div>
    <div #sdktabinfo *ngIf="activeTab && activeTab.type" class="tab-info">
        <ng-container [ngComponentOutlet]="activeTab.type" [ndcDynamicInputs]="activeTab.inputs" [ndcDynamicOutputs]="activeTab.outputs"></ng-container>
    </div>
</div>
