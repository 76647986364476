<div class="content">
    <div class="copyright">Copyright &copy; {{ date | date:'yyyy' }}</div>
    <div class="logo">
        <img title="soo-doh-kohd" alt="soo-doh-kohd" src="assets/images/sdk.png" height="30">
    </div>
    <div class="copyright-mobile">
        <div>Copyright &copy; {{ date | date:'yyyy' }}</div>
        <div>soo-doh-kohd</div>
    </div>
    <div class="access">My Custom Footer</div>
</div>

