import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

//******************************************************************************
//  NPM (Packages) External
//******************************************************************************
import { SDKDatagridModule } from 'sdk-datagrid';
import { SDKGoogleMapModule } from 'sdk-google-map';
import { SDKLoadingModule } from 'sdk-loading';
import { SDKTabsModule } from 'sdk-tabs';

const packages = [
    CommonModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,

    SDKDatagridModule,
    SDKGoogleMapModule,
    SDKLoadingModule,
    SDKTabsModule,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        packages
    ],
})

export class ExternalImports { }
