import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DatePipe, formatCurrency, formatNumber } from '@angular/common';

@Component({
	selector: 'sdk-textbox',
	templateUrl: './sdk-textbox.component.html',
	styleUrls: ['./sdk-textbox.component.scss']
})

export class SDKTextboxComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
	@Input() validCharacters: string = "";
	@Input() value: string = "";
	@Input() hint: string = "";
	@Input() pattern: string = "";
	@Input() locale: string = "en-US";
	@Input() multiLine: boolean = false;
	@Input() border: string | undefined;
	@Input() padding: string | undefined;
	@Input() margin: string | undefined;
	@Input() height: string | undefined;
	@Input() width: string | undefined;
	@Input() style: string | undefined;
	@Input() class: string | undefined;
	@Input() showCalendarIcon: boolean = true;
	@Input() calHeight: string = "180px";
	@Input() calWidth: string = "180px";
	@Input() calFontSize: string = "1.0em";
	@Output() blurCallBackEvent: EventEmitter<any> = new EventEmitter();
	@Output() changeCallBackEvent: EventEmitter<any> = new EventEmitter();
	@Output() enterCallBackEvent: EventEmitter<any> = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
	@ViewChild("component") component: ElementRef | undefined;
	@ViewChild("text") text: ElementRef | undefined;
	@ViewChild("calendar") calendar: ElementRef | undefined;

	protected calendarDate: string = "";
	protected showCalendar: boolean = false;
	protected lockCalendar: boolean = false;

	private pArray: any = [];
	private fArray: any = [];

    //******************************************************************************
    //  Component Life-cycle Methods
    //******************************************************************************
	protected ngOnInit() {
		if (this.validCharacters) {
			this.validCharacters = this.validCharacters.toString().toLocaleLowerCase();

			switch (this.validCharacters) {
				case "calendar":
					if (!this.pattern || this.pattern === "") this.pattern = "YYYY-MM-DD";

					let divider = this.pattern.replace(/[ymdYMD]/g, "").substring(0, 1);
					let dividerArray = this.pattern.split(divider);

					this.hint = this.pattern;
					this.createPattern(dividerArray[0].replace(/[ymdYMD]/g, "#") + divider + dividerArray[1].replace(/[ymdYMD]/g, "#") + divider + dividerArray[2].replace(/[ymdYMD]/g, "#"));

					if (this.value && this.value !== "") {
						let datePipe = new DatePipe(this.locale);
						let pattern = this.pattern!.replace(/[yY]/g, "y").replace(/[mM]/g, "M").replace(/[dD]/g, "d");
						let value = this.value.split("T")[0];

						this.value = datePipe.transform(value, pattern) || "";
					}

					setTimeout(() => {
						if (!this.width) {
							this.text!.nativeElement.style.width = `${this.pattern!.length * 10}px`;
						}

						if (this.component!.nativeElement.parentNode.hasAttribute("nocomponent")) {
							this.showCalendarIcon = false;
						}
					}, 1);

					break;

				case "email":
					this.hint = "name@company.com";
					break;

				case "custom":
					if (this.pattern
						&& this.pattern.indexOf("#") > -1
						&& !this.pattern.startsWith("[")
						&& !this.pattern.endsWith("]")
					) {
						this.hint = this.pattern;
						this.createPattern();
					}
					break;
			}
		}
	}

	protected ngAfterViewInit() {
		setTimeout(() => {
			if (this.validCharacters === "calendar" && this.component && this.text) {
				this.component.nativeElement.style.width = (this.text.nativeElement.clientWidth + 25) + "px";
			}
		}, 100);
	}

    //*************************************************************************
    //  Protected Methods
    //*************************************************************************
	protected onKeyDown(event: any) {
		this.showCalendar = false;

		if (event.key === "Enter" && !event.shiftKey) {
			this.text!.nativeElement.blur();

			if (this.enterCallBackEvent.observed) {
				this.enterCallBackEvent.emit(event.target.value);
			}
		} else if (
			event.key === "Backspace"
			|| event.key === "Tab"
			|| event.key === "ArrowLeft"
			|| event.key === "ArrowRight"
			|| event.key === "Meta"
			|| event.key === "Control"
		) {
			// DO NOTHING
		} else {
			let text = this.removeSelection(event);

			if (!(event.metaKey && event.key === "v") && !(event.metaKey && event.key === "c")) {
				switch (this.validCharacters) {
					case "alpha":
						if (!event.key.match(/[a-zA-Z]/)) {
							event.preventDefault();
						}

						break;

					case "numeric":
						if (!event.key.match(/[0-9]/)) {
							event.preventDefault();
						}

						break;

					case "alphanumeric":
						if (!event.key.match(/[a-zA-Z0-9]/)) {
							event.preventDefault();
						}

						break;

					case "decimal":
					case "currency":
						if (!event.key.match(/[-0-9.]/)
							|| (event.key === "." && text.match(/[.]/g))
							|| (event.key === "-" && (event.target.selectionStart !== 0 || text.match(/[-]/g)))
						) {
							event.preventDefault();
						}

						let periodArray = text.toString().split(".");

						if (this.validCharacters === "currency" && periodArray.length > 1 && periodArray[1].length >= 2) {
							event.preventDefault();
						}

						break;

					case "calendar":
						if (!event.key.match(/[0-9]/)
							|| text.replace(/[^0-9]/g, "").length === this.pattern!.replace(/[^ymdYMD]/g, "").length
						) {
							event.preventDefault();
						}

						break;

					case "email":
						if (!event.key.match(/[a-zA-Z0-9._%+-@]/)
							|| (event.key === "@" && text.match(/[@]/g))
							|| (!text.match(/[@]/g) && text.length >= 64)
							|| (text.match(/[@]/g) && text.length >= 254)
						) {
							event.preventDefault();
						}

						let atArray = text.toString().split("@");

						if (atArray.length > 1
							&& event.target.selectionStart > atArray[0].length
							&& !event.key.match(/[a-zA-Z0-9-.]/)
						) {
							event.preventDefault();
						}

						break;

					case "latitude":
						let latrx = /^([\-]?|[\-]?[1-9]|[\-]?[1-8][0-9]|[\-]?90)(?:[.]\d{0,15})?$/;
						let lattext = text + event.key;

						if (!lattext.match(new RegExp(latrx))) {
							event.preventDefault();
						}

						break;

					case "longitude":
						let longrx = /^([\-]?|[\-]?[1-9]|[\-]?[1-9]\d|[\-]?[1][0-7]\d|[\-]?180)(?:[.]\d{0,15})?$/;
						let longtext = text + event.key;

						if (!longtext.match(new RegExp(longrx))) {
							event.preventDefault();
						}

						break;

					case "custom":
						if (this.pattern
							&& this.pattern.indexOf("#") > -1
							&& !this.pattern.startsWith("[")
							&& !this.pattern.endsWith("]")
							&& (!event.key.match(/[0-9]/) || text.length === this.pattern.length)
						) {
							event.preventDefault();
						}

						if (this.pattern
							&& this.pattern.startsWith("[")
							&& this.pattern.endsWith("]")
						) {
							if (!event.key.match(new RegExp(this.pattern, "g"))) {
								event.preventDefault();
							}
						}

						if (this.pattern
							&& this.pattern.startsWith("^")
							&& this.pattern.endsWith("$")
						) {
							let newText = text + event.key;

							if (!newText.match(new RegExp(this.pattern))) {
								event.preventDefault();
							}
						}

						break;

					default:
						break;
				}
			}
		}
	}

	protected onBlur(event: any) {
		let text = event.target.value;

		if (this.validCharacters && text && text !== "") {
			text = this.cleanText(text);
			text = this.formatText(text);

			event.target.value = text;
		}

		if (this.validCharacters) {
			this.validCharacters = this.validCharacters.toString().toLocaleLowerCase();

			switch (this.validCharacters) {
				case "calendar":
					if (!this.lockCalendar) {
						this.showCalendar = false;
					}

					break;
			}
		}

		if (this.blurCallBackEvent.observed) {
			this.blurCallBackEvent.emit(this.text!.nativeElement.value);
		}
	}

	protected onInput(event: any) {
		if (this.validCharacters === "decimal"
			|| this.validCharacters === "currency"
		) {
			this.setNumericText(event);
		}

		if (this.validCharacters === "calendar") {
			this.setCalendarText(event);
		}

		if (this.validCharacters === "email") {
			this.setEmailText(event);
		}

		if (this.validCharacters === "latitude") {
			this.setLatitude(event);
		}

		if (this.validCharacters === "longitude") {
			this.setLongitude(event);
		}

		if (this.validCharacters === "custom" && this.pArray.length > 0) {
			this.setCustomText(event);
		}

		if (this.changeCallBackEvent.observed) {
			this.changeCallBackEvent.emit(this.text!.nativeElement.value);
		}
	}

	protected onCut(event: any) {
		let textLeft = event.target.value.toString().substring(0, event.target.selectionStart);
		let textRight = event.target.value.toString().substring(event.target.selectionEnd);

		let text = textLeft + textRight;

		let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
		clipboardData.items.add(text, 'text/plain');
	}

	protected onPaste(event: any) {
		let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
		let text = clipboardData.getData('text');

		let textLeft = event.target.value.toString().substring(0, event.target.selectionStart);
		let textRight = event.target.value.toString().substring(event.target.selectionEnd);

		text = textLeft + text + textRight;

		event.preventDefault();

		if (
			(this.validCharacters === "custom" && this.pattern.startsWith("^") && this.pattern.endsWith("$"))
			|| this.validCharacters === "latitude"
			|| this.validCharacters === "longitude"
		) {
			event.target.value = "";
		} else {
			text = this.cleanText(text);

			event.target.value = text;
		}

		if (this.changeCallBackEvent.observed) {
			this.changeCallBackEvent.emit(this.text!.nativeElement.value);
		}
	}

	protected showComponent(event: any) {
		if (this.validCharacters) {
			this.validCharacters = this.validCharacters.toString().toLocaleLowerCase();

			switch (this.validCharacters) {
				case "calendar":
					if (!this.component!.nativeElement.parentNode.hasAttribute("nocomponent")) {
						this.calendarDate = this.text!.nativeElement.value;
						this.showCalendar = true;
					}

					break;
			}
		}
	}

	protected setDate(date: any) {
		this.text!.nativeElement.value = this.formatText(date);

		if (this.changeCallBackEvent.observed) {
			this.changeCallBackEvent.emit(this.text!.nativeElement.value);
		}

		this.showCalendar = false;
	}

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
	private setNumericText(event: any) {
		let text = event.target.value;
		let newText = "";
		let beforeLength = text.length;
		let afterLength = 0;
		let cursorStart = event.target.selectionStart;
		let cursor = 0;

		event.preventDefault();

		newText = text.toString().replace(/[^-0-9.]/g, "");
		newText = newText.toString().replace(/\B(?=([0-9]{3})+(?![0-9]))/g, ",");

		let periodArray = newText.toString().split(".");

		if (periodArray.length > 1) {
			newText = periodArray[0] + "." + periodArray[1].replace(/[,]/g, "");
		}

		afterLength = newText.length;

		cursor = cursorStart + (afterLength - beforeLength);

		event.target.value = newText;
		event.target.selectionStart = cursor;
		event.target.selectionEnd = cursor;
	}

	private setCalendarText(event: any) {
		let text = event.target.value;
		let newText = "";
		let beforeLength = text.length;
		let afterLength = 0;
		let cursorStart = event.target.selectionStart;
		let cursor = 0;

		event.preventDefault();

		newText = text.toString().replace(/[^0-9]/g, "");

		for (let x = 0; x < this.pArray.length; x++) {
			newText = newText.replace(new RegExp(this.pArray[x]), this.fArray[x]);
		}

		afterLength = newText.length;

		cursor = cursorStart + (afterLength - beforeLength);

		event.target.value = newText;
		event.target.selectionStart = cursor;
		event.target.selectionEnd = cursor;
	}

	private setEmailText(event: any) {
		let text = event.target.value;
		let newText = "";
		let beforeLength = text.length;
		let afterLength = 0;
		let cursorStart = event.target.selectionStart;
		let cursor = 0;

		event.preventDefault();

		newText = text.toString().replace(/[^a-zA-Z0-9._%+-@]/g, "");

		let atArray = newText.toString().split("@");

		if (atArray.length > 1) {
			newText = atArray[0].substring(0, 63) + "@" + atArray.slice(1).toString().replace(/[^a-zA-Z0-9-.]/g, "");
		} else {
			newText = newText.substring(0, 63);
		}

		newText = newText.substring(0, 253);

		afterLength = newText.length;

		cursor = cursorStart + (afterLength - beforeLength);

		event.target.value = newText;
		event.target.selectionStart = cursor;
		event.target.selectionEnd = cursor;
	}

	private setLatitude(event: any) {
		let text = event.target.value;
		let newText = text;

		event.preventDefault();

		if (Number(text) < -90) {
			newText = -90;
		}
		if (Number(text) > 90) {
			newText = 90;
		}

		event.target.value = newText;
	}

	private setLongitude(event: any) {
		let text = event.target.value;
		let newText = text;

		event.preventDefault();

		if (Number(text) < -180) {
			newText = -180;
		}
		if (Number(text) > 180) {
			newText = 180;
		}

		event.target.value = newText;
	}
	
	private setCustomText(event: any) {
		let text = event.target.value;
		let newText = "";
		let beforeLength = text.length;
		let afterLength = 0;
		let cursorStart = event.target.selectionStart;
		let cursor = 0;

		event.preventDefault();

		if (text === "+1 ") text = "";
		newText = text.toString().replace(/[^0-9]/g, "");

		for (let x = 0; x < this.pArray.length; x++) {
			newText = newText.replace(new RegExp(this.pArray[x]), this.fArray[x]);
		}

		afterLength = newText.length;

		cursor = cursorStart + (afterLength - beforeLength);

		event.target.value = newText;
		event.target.selectionStart = cursor;
		event.target.selectionEnd = cursor;
	}

	private removeSelection(event: any): any {
		let textLeft = event.target.value.toString().substring(0, event.target.selectionStart);
		let textRight = event.target.value.toString().substring(event.target.selectionEnd + 1);

		return textLeft + textRight;
	}

	private cleanText(text: any): any {
		if (this.validCharacters?.toString().startsWith("[")) {
			let format = "[^" + this.validCharacters?.toString().split("[")[1];

			text = text.toString().replace(new RegExp(format, "g"), "");
		} else {
			switch (this.validCharacters) {
				case "alpha":
					text = text.toString().replace(/[^a-zA-Z]/g, "");
					break;

				case "numeric":
					text = text.toString().replace(/[^0-9]/g, "");
					break;

				case "alphanumeric":
					text = text.toString().replace(/[^a-zA-Z0-9]/g, "");
					break;

				case "decimal":
				case "currency":
					text = text.toString().replace(/[^0-9.-]|(?:\/[.].*)[.]|(?!^)-/g, "");
					break;

				case "calendar":
					if (this.pattern) {
						let re = "[^0-9" + this.encodeText(Array.from(new Set(this.pattern.replace(/[a-zA-Z0-9]/g, "").split(""))).join("")) + "]";

						text = text.toString().replace(new RegExp(re, "g"), "");
					}
					break;

				case "email":
					text = text.toString().replace(/[^a-zA-Z0-9._%+-@]/g, "");

					let atArray = text.toString().split("@");

					if (atArray.length > 1) {
						let periodArray = atArray[1].toString().split(".");

						if (periodArray.length > 1) {
							let type = periodArray.slice(periodArray.length - 1);
							type = type.toString().replace(/[^a-zA-Z]/g, "");

							periodArray.pop();
							text = atArray[0] + "@" + periodArray.join(".") + "." + type;
						}
					}

					break;

				case "latitude":
				case "longitude":
					let re = "(" + this.pattern + ")";
					text = text.toString().replace(new RegExp(re, "g"), "$1");

					break;

				case "custom":
					if (this.pattern) {
						if (this.pattern.indexOf("#") > -1 && !this.pattern.startsWith("[") && !this.pattern.endsWith("]")) {
							let re = "[^0-9" + this.encodeText(Array.from(new Set(this.pattern.split(""))).join("")) + "]";
							text = text.toString().replace(new RegExp(re.replace("#", ""), "g"), "");
							text = text.substring(0, this.pattern.length);
						}

						if (this.pattern.startsWith("[") && this.pattern.endsWith("]")) {
							let re = this.pattern.replace("[", "[^");

							if (this.pattern.startsWith("[^")) {
								re = this.pattern.replace("[^", "[");
							}

							text = text.toString().replace(new RegExp(re, "g"), "");
						}

						if (this.pattern.startsWith("^") && this.pattern.endsWith("$")) {
							let re = "(" + this.pattern + ")";
							text = text.toString().replace(new RegExp(re, "g"), "$1");
						}
					}

					break;

				default:
					break;
			}
		}

		return text;
	}

	private formatText(text: any): any {
		if (this.validCharacters === "currency") {
			text = formatCurrency(parseFloat(text), this.locale, this.pattern ?? '$');
		} else if (this.validCharacters === "custom" || this.validCharacters === "latitude" || this.validCharacters === "longitude") {
			// DO NOTHING
		} else if (this.validCharacters === "calendar") {
			const datepipe: DatePipe = new DatePipe(this.locale)

			let pattern = this.pattern!.replace(/[yY]/g, "y").replace(/[mM]/g, "M").replace(/[dD]/g, "d");

			try {
				text = datepipe.transform(text, pattern, undefined, this.locale);
				this.text!.nativeElement.style.backgroundColor = "";
			} catch {
				setTimeout(() => {
					this.text!.nativeElement.style.backgroundColor = "rgb(255, 210, 217)";
					this.text!.nativeElement.focus();
				}, 1);
			}
		} else {
			if (this.pattern) {
				text = formatNumber(parseFloat(text), this.locale, this.pattern);
			}
		}

		return text;
	}

	private createPattern(pattern: string = "") {
		if (pattern === "") {
			pattern = this.pattern?.trim() ?? "";
		}
		let ws: any = pattern.replace(/([\#])\1+/g, "#");

		let wsPattern = "";
		let breakArray = [];
		let cnt = 1;
		let wsBreak = "";

		for (let x = 0; x < ws.length; x++) {
			if (ws[x] === "#") {
				wsPattern += `$${cnt}`;
				cnt++;
			} else {
				wsPattern += ws[x];
			}

			wsBreak = ws[x];

			if (ws[x] === " " || ws[x] === "-" || ws[x] === "." || ws[x] === "/" || ws[x] === "_") {
				breakArray.push(wsBreak);
				wsBreak = "";
			}
		}

		let charArray = [];
		let chars = "";

		for (let x = 0; x < pattern.length; x++) {
			if (pattern[x] === "#") {
				chars += "#";
			} else {
				if (chars !== "") {
					charArray.push(chars);
				}
				chars = "";
			}
		}

		// Add any remaining chars.
		if (chars !== "") {
			charArray.push(chars);
		}

		let patternArray = [];

		for (let x = 0; x < charArray.length; x++) {
			patternArray.push(`[0-9]{1,${charArray[x].length}}`);
		}

		this.pArray = [];
		this.fArray = [];
		let wsFilter = ws;
		let wspFilter = wsPattern;
		let f = "";
		let p = "";

		for (let l = 0; l < patternArray.length; l++) {
			f = wsFilter.split(breakArray[l])[0];
			wsFilter = wsFilter.substring(f.length + 1);
			p = wspFilter.split(breakArray[l])[0];
			wspFilter = wspFilter.substring(p.length + 1);

			for (let x = l; x < patternArray.length; x++) {
				if (this.pArray[x] === undefined) this.pArray.push("");
				if (this.fArray[x] === undefined) this.fArray.push("");

				let m = (l > 0) ? "*" : "";

				// build left-side (pArray)
				if (f.startsWith("#") && f.endsWith("#")) { // number only
					if (x === l) {
						this.pArray[x] += m + "(" + patternArray[l] + ")";
					} else {
						this.pArray[x] += this.encodeText(f).replace("#", m + "(" + patternArray[l].replace("1,", "") + ")") + this.encodeText(breakArray[l]);
					}
				}
				if (!f.startsWith("#") && !f.endsWith("#")) { // wrapper
					if (x === l) {
						this.pArray[x] += m + "(" + patternArray[l] + ")";
					} else if (x === (l + 1)) {
						this.pArray[x] += m + "(" + patternArray[l].replace("1,", "") + ")" + this.encodeText(breakArray[l]);
					} else {
						this.pArray[x] += this.encodeText(f).replace("#", m + "(" + patternArray[l].replace("1,", "") + ")") + this.encodeText(breakArray[l]);
					}
				}
				if (!f.startsWith("#") && f.endsWith("#")) { // starts with non-digit
					if (x === l) {
						this.pArray[x] += m + "(" + patternArray[l] + ")";
					} else {
						this.pArray[x] += m + "(" + patternArray[l].replace("1,", "") + ")" + this.encodeText(breakArray[l]);
					}
				}
				if (f.startsWith("#") && !f.endsWith("#")) { // ends with non-digit
					if (x === l) {
						this.pArray[x] += m + "(" + patternArray[l] + ")";
					} else {
						this.pArray[x] += m + "(" + patternArray[l].replace("1,", "") + ")" + this.encodeText(breakArray[l]);
					}
				}

				// build right-side (fArray)
				if (f.startsWith("#") && f.endsWith("#")) { // number only
					if (l === x) {
						this.fArray[x] += p;
					} else {
						this.fArray[x] += p + (breakArray[l] ?? "");
					}
				}
				if (!f.startsWith("#") && !f.endsWith("#")) { // wrapper
					if (l === x) {
						this.fArray[x] += p.replace(/[^$0-9]/g, "");
					} else {
						this.fArray[x] += p + (breakArray[l] ?? "");
					}
				}
				if (!f.startsWith("#") && f.endsWith("#")) { // starts with non-digit
					if (l === x) {
						this.fArray[x] += p;
					} else {
						this.fArray[x] += p + (breakArray[l] ?? "");
					}
				}
				if (f.startsWith("#") && !f.endsWith("#")) { // ends with non-digit
					if (l === x) {
						this.fArray[x] += p.replace(/[^$0-9]/g, "");
					} else {
						this.fArray[x] += p + (breakArray[l] ?? "");
					}
				}
			}
		}
	}

	private encodeText(text: any): any {
		return text.replace(/[|{}\\()[\]^$+*?.+]/g, '\\$&').replace(/\s/, "\\s");
	}
}
