import { Component } from '@angular/core';
import { Location } from "@angular/common";

import { AppSettings } from '~/core/models/appsettings';

@Component({
    selector: 'notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})

export class NotFoundComponent {
    public appSettings: AppSettings = new AppSettings();

    constructor(
        private location: Location
    ) {}

    public goBack() {
        this.location.back();
    }
}
