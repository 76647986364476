import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sdk-datagrid-export-option',
    templateUrl: './export-option.component.html',
    styleUrls: ['./export-option.component.scss']
})

export class SDKDataGridExportOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() isExporting: boolean = false;
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected includeAllData: boolean = false;

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected apply() {
        this.applyEvent.emit(this.includeAllData);
    }

    protected close() {
        this.closeEvent.emit();
    }
}
