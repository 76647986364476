import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { DynamicIoModule } from 'ng-dynamic-component';

import { SDKTabsComponent } from './sdk-tabs.component';

import { SDKMessageModule } from 'sdk-message';

@NgModule({
  declarations: [
    SDKTabsComponent,
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    DynamicIoModule,
    SDKMessageModule,
  ],
  exports: [
    SDKTabsComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SDKTabsModule { }
