<div class="sdk-datagrid-window">
    <sdk-message [(message)]="msg"></sdk-message>
    <div class="header">
        <span class="title">Settings</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <sdk-select
            [options]="settingsList"
            optionStyle="border-radius: 4px;"
            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
            displayValue="Name"
            [selectedOptions]="[activeSetting]"
            (selectChangeEvent)="load($event)">
        </sdk-select>
    </div>
    <div class="content">
        <div class="settings">
            <div class="name">
                <label *ngIf="error !== ''" class="error">{{ error }}</label>
                <sdk-textbox *ngIf="error === ''" hint="Enter Settings Name..." [value]="name === '[Current Settings]' ? '' : name" (changeCallBackEvent)="setName($event)"></sdk-textbox>
            </div>
            <div class="values" [ngClass]="{ adjusted: activeSetting.Active }">
                <div [innerHTML]="viewerText"></div>
            </div>
            <div *ngIf="activeSetting.Active" class="notes">
                * Indicates active settings.
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="sdk-button delete" [disabled]="activeSetting.Name === '[Current Settings]' ? 'disabled' : ''" [ngClass]="{ 'disabled': activeSetting.Name === '[Current Settings]' }" (click)="remove()">Delete</button>
        <button *ngIf="error === ''" class="sdk-button" (click)="save()">Save</button>
        <button *ngIf="error === ''" class="sdk-button" (click)="apply()">Apply</button>
    </div>
</div>

<!-- Lock -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
