import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

import { Subscription } from 'rxjs';

import { GraphService } from '~/core/services/graph.service';
import { AppConfigService } from '~/core/services/appconfig.service';
import { AppSettingsService } from '~/core/services/appsettings.service';

import { AppSettings } from '~/core/models/appsettings';

@Component({
    selector: 'core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
    @Input() title = "";
    @Output() setLayoutCssClass = new EventEmitter();
    @Output() reloadPage = new EventEmitter();

    public appSettings: AppSettings = new AppSettings();
    public env: string = "";
    public itemsClass = "";
    public initials: string = "";
    public showPhoto: boolean = false;

    private routerSubscription: Subscription | undefined;

    constructor(
        private router: Router,
        private graphService: GraphService,
        private appConfigService: AppConfigService,
        private appSettingsService: AppSettingsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        if (this.appConfigService.getConfig("environment")) {
            this.env = this.appConfigService.getConfig("environment").toString().toLocaleUpperCase();
        }
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        this.routerSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.handleNavigationChange();
            }
        });

        await this.initializeSettings();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public navigateTo(url: string) {
        this.router.navigateByUrl(url);
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async initializeSettings() {
        this.appSettings = await this.appSettingsService.getSettings();

        await this.setUI();
    }

    private async handleNavigationChange() {
        await this.initializeSettings();
    }

    private async getUserInfo() {
        if (this.appSettings.user?.photo !== "") {
            this.showPhoto = true;
        } else {
            this.graphService.getUserPhoto().then((photo: any) => {
                this.appSettings.user.photo = photo;

                if (photo !== "") {
                    this.showPhoto = true;
                }
    
                this.appSettingsService.saveSettings(this.appSettings);
            });
        }
    }

    private async setUI() {
        this.changeDetectorRef.detectChanges();

        await this.getUserInfo();
    }
}
