import { Component } from '@angular/core';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
    constructor() {}

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
}
