import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { SDKDataGridMessage } from '../../models/datagrid-message';

import { Clone } from '../../utils/clone';

@Component({
    selector: 'sdk-datagrid-chart-option',
    templateUrl: './chart-option.component.html',
    styleUrls: ['./chart-option.component.scss']
})

export class SDKDataGridChartOptionComponent {
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() chart: any = "";
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    public chartType = "bar";
    public columnLabel = "";
    public columnDataX = "";
    public columnDataY = "";
    public dataOption = false;

    protected _columns: SDKDataGridColumn[] = [];

    /**************************************************************************
    * Message Variable
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    public ngOnChanges(_args: any) {
        this._columns = Clone.deepCopy(this.columns);

        this.initialize();
    }

    private initialize() {
        this._columns = this._columns.filter((column: SDKDataGridColumn) => column.isVisible && !column.isAction);
        
        if (this.chart === "") {
            this.chart = {
                Type: "bar",
                Show: false,
                Label: "",
                DataX: "",
                DataY: "",
                Option: false
            };
        }

        this.chartType = this.chart.Type;
        this.columnLabel = this.chart.Label;
        this.columnDataX = this.chart.DataX;
        this.columnDataY = this.chart.DataY;
        this.dataOption = this.chart.Option;
    }

    public getColumnOriginalName(column: SDKDataGridColumn) {
        let originalName = column.Name;

        if (column.FriendlyName && column.FriendlyName !== "") {
            originalName = column.FriendlyName;
        } else if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public getValue(event: any) {
        return event.target.value;
    }

    public reset() {
        this.message = {
            Title: "Reset Chart",
            Text: "Are you sure?",
            OKText: "YES",
            CancelText: "NO",
            OK: () => {
                this.chart = "";

                this.initialize();
            },
            Cancel: () => {
                this.showMessage = false;
            }
        };

        this.showMessage = true;
    }

    public apply() {
        let show = true;

        if (['bar', 'line', 'radar'].indexOf(this.chartType) > -1 && (this.columnLabel === "" || this.columnDataX === "" || this.columnDataY === "")) show = false;
        if (['doughnut', 'pie', 'polarArea'].indexOf(this.chartType) > -1 && (this.columnLabel === "" || this.columnDataY === "")) show = false;

        this.chart = {
            Type: this.chartType,
            Show: show,
            Label: this.columnLabel,
            DataX: this.columnDataX,
            DataY: this.columnDataY,
            Option: this.dataOption
        };

        this.applyEvent.emit(this.chart);
    }

    public close() {
        this.closeEvent.emit();
    }
}
