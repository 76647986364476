import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { SDKDataGridCustomFilter } from '../../models/datagrid-custom-filter';
import { SDKDataGridMessage } from '../../models/datagrid-message';

import { Filters, FilterType } from '../../enums/filters';
import { Clone } from '../../utils/clone';

@Component({
    selector: 'sdk-datagrid-filter-option',
    templateUrl: './filter-option.component.html',
    styleUrls: ['./filter-option.component.scss']
})

export class SDKDataGridFilterOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() customFilters: SDKDataGridCustomFilter[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected columnList: SDKDataGridColumn[] = [];
    protected filterList: any[] = [];

    private _columns: SDKDataGridColumn[] = [];
    private _customFilters: SDKDataGridColumn[] = [];

    /**************************************************************************
    * Message Variables
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
    protected ngOnChanges(_args: any) {
        this._columns = Clone.deepCopy(this.columns);
        this._customFilters = Clone.deepCopy(this.customFilters);

        Promise.all([
            this._columns.filter((column: SDKDataGridColumn) => column.setFilterValues !== undefined).forEach(async (fv: any) => {
                await fv.setFilterValues().then((results: any) => {
                    fv.FilterValues = results;
                });
            }),

            this._customFilters.filter((custom: SDKDataGridColumn) => custom.setFilterValues !== undefined).forEach(async (fv: any) => {
                await fv.setFilterValues().then((results: any) => {
                    fv.FilterValues = results;
                });
            })
        ]).then(() => {
            this.initialize();
        });
    }

    /**************************************************************************
    * Setup Methods
    **************************************************************************/
    protected initialize() {
        this.filterList = [];
        this.columnList = [];

        this._customFilters.forEach((filter: any) => {
            if (filter.Type === FilterType.Dropdown && (!filter.FilterTypes || filter.FilterTypes.length === 0)) {
                filter.FilterTypes = [Filters.Contains, Filters.Empty, Filters.Equals, Filters.GreaterThan, Filters.GreaterThanOrEqual, Filters.InList, Filters.LessThan, Filters.LessThanOrEqual, Filters.NotContains, Filters.NotEmpty, Filters.NotEquals];
            }
            if (filter.FilterMultiSelect && filter.FilterValues && filter.FilterValues.length > 0) {
                filter.FilterTypes = [Filters.Equals, Filters.NotEquals];
            }

            let operation = filter.Filter?.operation;

            if (!operation && filter.FilterTypes.length === 1) {
                operation = filter.FilterTypes[0];
            }

            this.filterList.push({
                Type: "Custom",
                Name: filter.Name,
                DisplayName: filter.DisplayName,
                Operation: operation,
                ValueType: filter.Type,
                ValueList: filter.FilterValues,
                MultiSelect: filter.FilterMultiSelect,
                Value: filter.Filter?.value ?? ""
            });
        });

        this._columns.filter((column: SDKDataGridColumn) => column.showFilter && column.isVisible && !column.isAction).forEach((column: SDKDataGridColumn) => {
            if (!column.FilterTypes || column.FilterTypes.length === 0) {
                column.FilterTypes = [Filters.Contains, Filters.Empty, Filters.Equals, Filters.GreaterThan, Filters.GreaterThanOrEqual, Filters.InList, Filters.LessThan, Filters.LessThanOrEqual, Filters.NotContains, Filters.NotEmpty, Filters.NotEquals];
            }
            if (column.FilterMultiSelect && column.FilterValues && column.FilterValues.length > 0) {
                column.FilterTypes = [Filters.Equals, Filters.NotEquals];
            }

            let operation = column.Filter?.operation;

            if (!operation && column.FilterTypes.length === 1) {
                operation = column.FilterTypes[0];
            }

            this.filterList.push({
                Type: "System",
                Name: column.Name,
                DisplayName: this.getColumnName(column),
                Operation: operation,
                ValueType: column.FilterType,
                ValueList: column.FilterValues,
                MultiSelect: column.FilterMultiSelect,
                Value: column.Filter?.value ?? ""
            });

            this.columnList.push(column);
        });
    }

    protected getFilters(filter: any) {
        let obj: any;
        let options: any = [];

        if (filter.Type === "Custom") {
            obj = this._customFilters.find((o: SDKDataGridCustomFilter) => o.Name === filter.Name);
        } else {
            obj = this._columns.find((o: SDKDataGridColumn) => o.Name === filter.Name);
        }

        obj.FilterTypes.forEach((type: any) => {
            options.push(type);
        })

        return options;
    }

    protected filterExists(value: any, filter: any) {
        let obj: any;

        if (filter.Type === "Custom") {
            obj = this._customFilters.find((o: SDKDataGridCustomFilter) => o.Name === filter.Name);
        } else {
            obj = this._columns.find((o: SDKDataGridColumn) => o.Name === filter.Name);
        }

        if (obj?.FilterTypes && obj.FilterTypes.length > 0) {
            if (Object.values(obj.FilterTypes).includes(value)) {
                return true;
            }
        } else {
            if (!obj.FilterTypes || obj.FilterTypes.length === 0 || Object.values(obj.FilterTypes).includes(value)) {
                return true;
            }
        }

        return false;
    }

    protected getColumnName(column: SDKDataGridColumn) {
        let originalName = column.Name;

        if (column.FriendlyName && column.FriendlyName !== "") {
            originalName = column.FriendlyName;
        } else if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    private validate() {
        let valid: boolean = true;

        this.filterList.forEach((filter: any) => {
            if (![FilterType.Checkbox, FilterType.TextboxOnly].includes(filter.ValueType) && filter.Value.length > 0 && !filter.Operation) valid = false;
        })

        return valid;
    }

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected addOperation(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.Name === filter.Name);

        let operation = event[0];

        switch (event[0]) {
            case Filters.Contains:
                operation = "Contains";
                break;
            case Filters.Empty:
                operation = "Empty";
                break;
            case Filters.Equals:
                operation = "Equals";
                break;
            case Filters.GreaterThan:
                operation = "GreaterThan";
                break;
            case Filters.GreaterThanOrEqual:
                operation = "GreaterThanOrEqual";
                break;
            case Filters.InList:
                operation = "InList";
                break;
            case Filters.LessThan:
                operation = "LessThan";
                break;
            case Filters.LessThanOrEqual:
                operation = "LessThanOrEqual";
                break;
            case Filters.NotContains:
                operation = "NotContains";
                break;
            case Filters.NotEmpty:
                operation = "NotEmpty";
                break;
            case Filters.NotEquals:
                operation = "NotEquals";
                break;
        }

        if (item) {
            item.Operation = [operation];
        }
    }

    protected addValue(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.Name === filter.Name);

        if (item) {
            item.Value = event.target.value;
        }
    }

    protected selectedValue(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.Name === filter.Name);

        if (item) {
            item.Value = event;
        }

        filter.Value = event;
    }

    protected toggleAny(filter: any) {
        let item: any = this.filterList.find((f: any) => f.Type === "Custom" && f.ValueType === "checkbox" && f.Name === filter.Name);

        if (item) {
            if (item.Value === "true") {
                item.Value = "";
            } else {
                item.Value = "true";
            }
        }
    }

    protected reset() {
        this.message = {
            Title: "Reset Filters",
            Text: "Are you sure?",
            OKText: "YES",
            CancelText: "NO",
            OK: () => {
                this.showMessage = false;

                this._columns.forEach((column: SDKDataGridColumn) => {
                    column.Filter = column._original.Filter;
                });

                this._customFilters.forEach((filter: SDKDataGridCustomFilter) => {
                    filter.Filter = filter._original.Filter;
                });

                this.initialize();
            },
            Cancel: () => {
                this.showMessage = false;
            }
        };

        this.showMessage = true;
    }

    protected apply() {
        let cont: boolean = true;

        if (!this.validate()) {
            cont = confirm("Some filters are missing data. Contniue anyway?");
        }

        if (cont) {
            this.filterList.forEach((filter: any) => {
                let ndx = this._columns.findIndex((column: SDKDataGridColumn) => column.Name === filter.Name);

                if (ndx > -1) {
                    if (filter.Value && (filter.Value !== undefined || filter.Value !== null || filter.Value !== "" || filter.Value.length > 0)) {
                        this._columns[ndx].Filter = { operation: filter.Operation, value: filter.Value };
                    } else if (filter.Operation && ["Empty", "NotEmpty"].includes(filter.Operation[0])) {
                        this._columns[ndx].Filter = { operation: filter.Operation, value: null };
                    } else {
                        this._columns[ndx].Filter = null;
                    }
                }

                ndx = this._customFilters.findIndex((custom: SDKDataGridCustomFilter) => custom.Name === filter.Name);

                if (ndx > -1) {
                    if (filter.Value && (filter.Value !== undefined || filter.Value !== null || filter.Value !== "" || filter.Value.length > 0)) {
                        this._customFilters[ndx].Filter = { operation: filter.Operation, value: filter.Value };
                    } else if (filter.Operation && ["Empty", "NotEmpty"].includes(filter.Operation[0])) {
                        this._customFilters[ndx].Filter = { operation: filter.Operation, value: null };
                    } else {
                        this._customFilters[ndx].Filter = null;
                    }
                }
            });

            this.applyEvent.emit({ option: "Filters", columns: this._columns, customFilters: this._customFilters });
        }
    }

    protected close() {
        this.closeEvent.emit();
    }
}
