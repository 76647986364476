import { TemplateRef } from "@angular/core";

import { FilterType } from "../enums/filters";

import { SDKDataGridFilter } from "./datagrid-filter";
import { SDKDataGridSort } from "./datagrid-sort";

export class SDKDataGridColumn {
    public Name: string = ""; // Original db name.
    public DisplayName?: string = "";  // System override of db name (Name).
    public FriendlyName?: string = "";  // User provides. Produces * indicator.
    public Sort?: SDKDataGridSort | null = null; // Sorting (asc/desc) applied to column data.
    public Filter?: SDKDataGridFilter | null = null; // Filters applied to column data.
    public Formulas?: any = null; // Formulas applied to column.

    public showSort?: boolean = true; // Allows you to turn on/off sorting capabilities.
    public showFilter?: boolean = true; // Allows you to turn on/off filtering capabilities.
    public showTooltip?: boolean = false;
    public isAction?: boolean = false; // Is the column associated to an action.
    public isVisible?: boolean = true; // Allows you to turn on/off the visibility of the column.

    public actionSide?: string = "right" // right or left

    // START: Filter properties
    public FilterMultiSelect?: boolean = false; // Allows multiple filter values to be selected.
    public FilterTypes?: any[] = []; // Allows you to define specific filter types. NOTE: If FilterMultiSelect = true, this property is forced to [Filters.Equals, Filters.NotEquals].
    public FilterValues?: any[] = []; // Allows you to define specific filter values.
    public FilterType?: FilterType = FilterType.Textbox; // Type of filter (Textbox, TextboxOnly, Checkbox, Dropdown).
    public FilterValueDefault?: any; // Default value for Dropdown type.
    // END: Filter properties

    // START: editTemplate option
    public allowEdit?: boolean = true;
    public required?: boolean = false;
    public notes?: string = "";
    public validCharacters?: string = "";
    public hint?: string = "";
    public pattern?: string = "";
    public height?: string = "";
    public width?: string = "";
    public border?: string = "";
    public style?: string = "";
    // END: editTemplate option

    // Custom templates
    public headerTemplate?: () => TemplateRef<any>;
    public dataTemplate?: () => TemplateRef<any>;
    public editTemplate?: () => TemplateRef<any>;

    // Custom methods
    public formatData?: (value: any) => string; // Optional callback method to format the data.
    public setFilterValues?: () => Promise<any[]>; // Optional callback method to load filter values.

    // Original values - used with resets
    public _original?: any = {
        index: null,
        FriendlyName: "",
        isVisible: true,
        Sort: null,
        Filter: null,
        Formulas: null
    };
}
