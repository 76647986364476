export enum FormulaOperation {
    None = "",
    Avg = "Avg",
    Rng = "Rng",
    Sum = "Sum"
}

export class SDKDataGridFormula {
    public Name: string = "";
    public Format: number = 0;
    public Operation: FormulaOperation = FormulaOperation.None; // Options ["Avg", "Rng", "Sum"].
    public Value?: number = 0
}
