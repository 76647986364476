<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Sorts</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <sdk-select
            [options]="columnList"
            optionStyle="border-radius: 4px;"
            [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
            [forceOptionBottom]="true"
            noValueLabel="ADD SORT..."
            displayValue="Value"
            [selectedOptions]="selectedValue"
            (selectChangeEvent)="addSort($event)">
        </sdk-select>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div *ngIf="sortList.length === 0" class="no-records">
            <span>Add a sort.</span>
        </div>
        <div class="box" *ngFor="let sort of sortList" cdkDragBoundary=".list" cdkDrag>
            <div class="delete">
                <span class="sdk-icon" (click)="deleteSort(sort)">delete</span>
            </div>
            <div class="name" [title]="getColumnName(sort)">{{ getColumnName(sort) }}</div>
            <div class="select">
                <div style="display: inline-flex;" (click)="sort.Sort.direction = 'asc'">
                    <input type="radio" class="data-option" (click)="sort.Sort.direction = 'asc'" [id]="sort.Name + '_asc'" [name]="sort.Name" [checked]="(sort.Sort.direction === 'asc') ? 'checked' : ''" value="asc">
                    <span class="data-label" [for]="sort.Name + '_asc'">ASC</span>
                </div>
                <div style="display: inline-flex;" (click)="sort.Sort.direction = 'desc'">
                    <input type="radio" class="data-option" (click)="sort.Sort.direction = 'desc'" [id]="sort.Name + '_desc'" [name]="sort.Name" [checked]="(sort.Sort.direction === 'desc') ? 'checked' : ''" value="desc">
                    <span class="data-label" [for]="sort.Name + '_desc'">DESC</span>
                </div>
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="sdk-button delete" (click)="reset()">Reset</button>
        <button class="sdk-button" (click)="apply()">Apply</button>
    </div>
</div>

<!-- Lock -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
