import { Component, EventEmitter, Input, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { SDKDataGridMessage } from '../../models/datagrid-message';

import { Clone } from '../../utils/clone';

@Component({
    selector: 'sdk-datagrid-columns-option',
    templateUrl: './columns-option.component.html',
    styleUrls: ['./columns-option.component.scss']
})

export class SDKDataGridColumnsOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() columns: SDKDataGridColumn[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected columnList: SDKDataGridColumn[] = [];
    protected allVisible: boolean = true;

    private _columns: SDKDataGridColumn[] = [];

    /**************************************************************************
    * Message Variables
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
    protected ngOnChanges(_args: any) {
        if (_args.columns?.currentValue !== undefined) {
            this._columns = Clone.deepCopy(this.columns);

            this.initialize();
        }
    }

    /**************************************************************************
    * Setup Methods
    **************************************************************************/
    private initialize() {
        // Remove any "Action" columns. They are NOT available for user updates.
        this.columnList = this._columns.filter((column: SDKDataGridColumn) => !column.isAction);
        this.allVisible = this.columnList.filter((column: SDKDataGridColumn) => !column.isVisible).length > 0 ? false : true;
    }

    protected getColumnName(column: SDKDataGridColumn) {
        let name: string = column.Name;

        name = (column.DisplayName && column.DisplayName !== "") ? column.DisplayName : name;
        name = (column.FriendlyName && column.FriendlyName !== "") ? column.FriendlyName : name;

        return name;
    }

    protected setFriendlyName(event: any, column: SDKDataGridColumn) {
        column.FriendlyName = event.target.value;
    }

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected groupVisibleColumns() {
        let visible: SDKDataGridColumn[] = this.columnList.filter((column: SDKDataGridColumn) => column.isVisible);
        let hidden: SDKDataGridColumn[] = this.columnList.filter((column: SDKDataGridColumn) => !column.isVisible);

        this.columnList = visible.concat(hidden);
    }

    protected toggleAllColumns(event: any) {
        this.allVisible = event.target.checked;

        this.columnList.forEach((column: SDKDataGridColumn) => {
            column.isVisible = this.allVisible;
        });
    }

    protected toggleColumn(event: any, column: SDKDataGridColumn) {
        column.isVisible = event.target.checked;
    }

    protected drop(event: any) {
        moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
    }

    protected reset() {
        this.message = {
            Title: "Reset Columns",
            Text: "Are you sure?",
            OKText: "YES",
            CancelText: "NO",
            OK: () => {
                this.showMessage = false;

                this.columnList.sort((a: SDKDataGridColumn, b: SDKDataGridColumn) => (a._original.index > b._original.index) ? 1 : -1);

                this.columnList.forEach((column: SDKDataGridColumn) => {
                    column.FriendlyName = column._original.FriendlyName;
                    column.isVisible = column._original.isVisible;
                });

                this.initialize();
            },
            Cancel: () => {
                this.showMessage = false;
            }
        };

        this.showMessage = true;
    }

    protected apply() {
        // Add 'left' action columns back into list.
        this._columns.filter((column: SDKDataGridColumn) => column.isAction && column.actionSide === "left").forEach((column: SDKDataGridColumn) => {
            this.columnList.unshift({ ...column });
        });

        // Add 'right' action columns back into list.
        this._columns.filter((column: SDKDataGridColumn) => column.isAction && column.actionSide === "right").forEach((column: SDKDataGridColumn) => {
            this.columnList.push({ ...column });
        });

        // Remove any sorting/filtering for columns that are no longer visible.
        this.columnList.forEach((column: SDKDataGridColumn) => {
            if (!column.isVisible) {
                column.Filter = column._original.Filter;
                column.Sort = column._original.Sort;
            }
        });

        this.applyEvent.emit({ option: "Columns", columns: this.columnList });
    }

    protected close() {
        this.closeEvent.emit();
    }
}
