import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'basics-tab',
    templateUrl: './basics-tab.component.html',
    styleUrls: ['./basics-tab.component.scss']
})

export class BasicsTabComponent extends BaseComponent {
    @Input() title: string = "";
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.tabChangedEvent.emit(false);
    }
}
