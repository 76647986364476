import { Component, EventEmitter, Input, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { SDKDataGridMessage } from '../../models/datagrid-message';
import { SDKDataGridSort } from '../../models/datagrid-sort';

import { Clone } from '../../utils/clone';
import { Sorts } from '../../enums/sorts';

@Component({
    selector: 'sdk-datagrid-sort-option',
    templateUrl: './sort-option.component.html',
    styleUrls: ['./sort-option.component.scss']
})

export class SDKDataGridSortOptionComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
    @Input() columns: SDKDataGridColumn[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    protected sortList: any[] = [];
    protected columnList: any[] = [];
    protected selectedValue: any = [];

    private _columns: SDKDataGridColumn[] = [];

    /**************************************************************************
    * Message Variables
    **************************************************************************/
    protected showMessage: boolean = false;
    protected message: SDKDataGridMessage = new SDKDataGridMessage();

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
    protected ngOnChanges(_args: any) {
        if (_args.columns?.currentValue !== undefined) {
            this._columns = Clone.deepCopy(this.columns);

            this.initialize();
        }
    }

    /**************************************************************************
    * Setup Methods
    **************************************************************************/
    private initialize() {
        this.sortList = [];

        this._columns
            .filter((column: SDKDataGridColumn) => column.showSort && column.isVisible && !column.isAction && column.Sort)
            .sort((a: any, b: any) => (a.Sort.position > b.Sort.position) ? 1 : -1)
            .forEach((column: SDKDataGridColumn) => {
                this.sortList.push({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: { position: column.Sort?.position, direction: column.Sort?.direction } });
            });

        this.setColumnList();
    }

    private setColumnList() {
        this.columnList = [];

        this._columns
            .filter((column: SDKDataGridColumn) => column.showSort && column.isVisible && !column.isAction)
            .forEach((column: any) => {
                if (this.sortList.filter((s: any) => s.Name === column.Name).length === 0) {
                    this.columnList.push({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Value: this.getColumnName(column) });
                }
            });
    }

    protected getColumnName(column: SDKDataGridColumn) {
        let name: string = column.Name;

        name = (column.DisplayName && column.DisplayName !== "") ? column.DisplayName : name;
        name = (column.FriendlyName && column.FriendlyName !== "") ? column.FriendlyName : name;

        return name;
    }

    /**************************************************************************
    * User Action Methods
    **************************************************************************/
    protected addSort(event: any) {
        let ndx: number = this._columns.findIndex((column: SDKDataGridColumn) => column.Name === event[0].Name);

        if (ndx > -1) {
            let column: SDKDataGridColumn = this._columns[ndx];

            this.sortList.push({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: { position: 0, direction: Sorts.ASC } });
        }

        this.selectedValue = [];
        this.setColumnList();
    }

    protected deleteSort(sort: any) {
        this.sortList = this.sortList.filter((f: any) => f.Name !== sort.Name);

        this.setColumnList();
    }

    protected drop(event: any) {
        moveItemInArray(this.sortList, event.previousIndex, event.currentIndex);
    }

    protected reset() {
        this.message = {
            Title: "Reset Sorts",
            Text: "Are you sure?",
            OKText: "YES",
            CancelText: "NO",
            OK: () => {
                this.showMessage = false;

                this._columns.forEach((column: SDKDataGridColumn) => {
                    column.Sort = column._original.Sort;
                });

                this.initialize();
            },
            Cancel: () => {
                this.showMessage = false;
            }
        };

        this.showMessage = true;
    }

    protected apply() {
        this._columns.forEach((column: SDKDataGridColumn) => {
            column.Sort = column._original.Sort;
        });

        this.sortList.forEach((sort: any, index: number) => {
            let ndx = this._columns.findIndex((column: SDKDataGridColumn) => column.Name === sort.Name);

            if (ndx > -1) {
                this._columns[ndx].Sort = { position: index, direction: sort.Sort.direction };
            }
        })

        this.applyEvent.emit({ option: "Sorts", columns: this._columns });
    }

    protected close() {
        this.closeEvent.emit();
    }
}
