import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'tab-seo',
    templateUrl: './tab-seo.component.html',
    styleUrls: ['./tab-seo.component.scss']
})

export class TabSeoComponent extends BaseComponent {
    @Input() data: any;
    @Output() tabChangedEvent: EventEmitter<any> = new EventEmitter();

    public page: any;
    public pages: any;

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.getPages();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
	public setData() {
        let ndx = this.data.seo.findIndex((s: any) => s.path === this.page.path);

        if (ndx > -1) {
            this.data.seo[ndx] = this.page;
        }

        this.tabChangedEvent.emit(this.data);
	}

    public setPage(event: any) {
        let page = event[0] === "[home]" ? "" : event[0];

        let ndx = this.data.seo.findIndex((s: any) => s.path === page);

        if (ndx > -1) {
            this.page = this.data.seo[ndx];
        } else {
            this.page = {
                path: "sdk_page",
                title: "New Page",
                icon: "sdk",
                isNav: true,
                metaTitle: "",
                metaDescription: "",
                metaKeywords: ""
            };

            this.data.seo.push(this.page);

            this.setData();
        }
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getPages() {
        this.pages = this.data.seo.map((page: any) => page.path === "" ? "[home]" : page.path);

        this.pages.unshift("[Add new page...]");
    }
}
