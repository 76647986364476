<div *ngIf="data" class="content">
    <div class="data">
        <div>
            <span class="label">Title</span>
            <span class="icon" [title]="'Title of the app shown in the header section.'">info</span>
            <sdk-textbox [value]="data.title" style="color: var(--gray)" (changeCallBackEvent)="data.title = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Version</span>
            <span class="icon" [title]="'The version of the app shown in the footer.'">info</span>
            <sdk-textbox [value]="data.version" style="color: var(--gray)" (changeCallBackEvent)="data.version = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Support Link</span>
            <span class="icon" [title]="'The support link (for email) shown on the Access page.'">info</span>
            <sdk-textbox [value]="data.supportLink" style="color: var(--gray)" (changeCallBackEvent)="data.supportLink = $event; setData()"></sdk-textbox>
        </div>

        <div>
            <span class="label">Public Access</span>
            <span class="icon" [title]="'Allow access to everyone on the web.'">info</span>
            <br />
            <span class="icon checkbox" (click)="data.publicAccess = !data.publicAccess; setData()">{{ data.publicAccess ? 'check_box' : 'check_box_outline_blank' }}</span>
        </div>

        <div>
            <div>
                <span class="label">Role</span>
                <div style="display: inline-flex; width: 100%;">
                    <sdk-textbox #sdktextbox [value]="role" style="color: var(--gray); width: 100%;" (changeCallBackEvent)="setValue($event)" (enterCallBackEvent)="addValue()"></sdk-textbox>
                    <span class="icon add" (click)="addValue()">add</span>
                </div>
            </div>
    
            <div>
                <span class="label">Roles</span>
                <div class="roles">
                    <ng-container *ngFor="let role of data.roles">
                        <div class="role" (click)="setAdjustValue(role)">
                            <span title="Delete" class="icon delete" (click)="deleteValue(role)">delete</span>
                            <span style="margin-left: 10px;">{{ role }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
