import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-google-map',
	templateUrl: './sdk-google-map.component.html'
})

export class SDKGoogleMapComponent {
    /**************************************************************************
    * Input/Output Parameters
    **************************************************************************/
	@Input() height: string = "";
	@Input() width: string = "";
	@Input() latitude: string = "";
	@Input() longitude: string = "";
	@Input() location: string = "";
	@Input() zoom: string = "10";
	@Input() border: string = "1px solid lightgray";

    /**************************************************************************
    * Component Variables
    **************************************************************************/
	@ViewChild("map") map: ElementRef | undefined;

	protected isLoading: boolean = false;

	protected _height: string = "";
	protected _width: string = "";

    /**************************************************************************
    * Component Lifecycle Methods
    **************************************************************************/
	protected ngOnChanges(_args: any) {
		if (_args.latitude || _args.longitude || _args.location || _args.zoom) {
			if (_args.latitude !== "" || _args.longitude !== "" || _args.location !== "") {
				this.loadMap();
			}
		}

		if ((_args.height && _args.height !== "") || (_args.width && _args.width !== "")) {
			this.setMap();
		}
	}

    /**************************************************************************
    * Private Methods
    **************************************************************************/
	private setMap() {
		if (this.height !== "" && this.width === "") {
			this._height = this.height;
			this._width = this.height;
		}
		else if (this.height === "" && this.width !== "") {
			this._height = this.width;
			this._width = this.width;
		}
		else if (this.height !== "" && this.width !== "") {
			this._height = this.height;
			this._width = this.width;
		} else {
			this._height = "300px";
			this._width = "300px";
		}
	}

	private loadMap() {
		this.isLoading = true;

		let src: string = "";

		if (this.latitude && this.latitude !== "" && this.longitude && this.longitude !== "") {
			src = `<iframe id="sdk-google-map-iframe" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=${this.latitude},${this.longitude}&amp;t=&amp;z=${this.zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>`;
		} else if (this.location && this.location !== "") {
			src = `<iframe id="sdk-google-map-iframe" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=${encodeURI(this.location)}&amp;t=&amp;z=${this.zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>`;
		} else {
			src = `<div style="display: flex; width: 100%; height: 100%; align-items: center;"><div style="width: 100%; text-align: center;">Insufficient data.</div></div>`;
		}

		setTimeout(() => {
			this.map!.nativeElement.innerHTML = src ?? "";

			let iframe = document.getElementById('sdk-google-map-iframe');

			if (iframe) {
				iframe.onload = () => {
					this.isLoading = false;
				}
			} else {
				this.isLoading = false;
			}
		}, 100);
	}
}
