<div #sdkdatagrid title="" class="sdk-datagrid-content">
    <!-- Header (Title) -->
    <div *ngIf="_options.header" class="sdk-datagrid-header" [ngClass]="{ 'tabs': _options.datasetTabs }">
        <div *ngIf="showGrid && _datasets.length === 0 && title !== ''" class="title" [ngClass]="{ 'centered': !titleStyle || titleStyle === '' }" [style]="titleStyle">{{ title }}</div>
        <div class="data" *ngIf="showGrid && _datasets.length > 1">
            <div *ngIf="!_options.datasetTabs" class="notabs" [ngClass]="{ 'notitle': title === ''}">
                <sdk-select
                    [label]="title"
                    [labelStyle]="'white-space: nowrap;' + titleStyle"
                    [options]="_datasets"
                    [optionValuesStyle]="'max-height: 150px; overflow: hidden; overflow-y: auto;'"
                    [selectedOptions]="[_selectedDataset]"
                    (selectChangeEvent)="selectDataset($event)">
                </sdk-select>
            </div>
            <div class="tabs" *ngIf="_options.datasetTabs">
                <sdk-tabs
                    [tabs]="datasetTabs"
                    [height]="'30px'"
                    (tabChangedEvent)="datasetChanged($event)">
                </sdk-tabs>
            </div>
        </div>
    </div>

    <!-- Minimized Options Button -->
    <div *ngIf="showGrid && _options.options" class="sdk-datagrid-options-menu" [ngClass]="{ 'tabs': _options.datasetTabs }">
        <div *ngIf="!_options.minimizeOptions" class="sizable">
            <div *ngIf="!_menuOptions" title="Show Options" (click)="_menuOptions = true;" class="sdk-icon">menu_open</div>
            <div *ngIf="_menuOptions" title="Hide Options" (click)="_menuOptions = false;" class="sdk-icon">close</div>
        </div>
        <div *ngIf="_options.minimizeOptions" class="always-on">
            <div *ngIf="!_menuOptions" title="Show Options" (click)="_menuOptions = true;" class="sdk-icon">menu_open</div>
            <div *ngIf="_menuOptions" title="Hide Options" (click)="_menuOptions = false;" class="sdk-icon">close</div>
        </div>
    </div>

    <!-- Options -->
    <div *ngIf="showGrid && _options.options" class="sdk-datagrid-options" [ngClass]="{ 'no-header': !_options.header, 'no-footer': !_options.footer, 'hide': _options.minimizeOptions && !_menuOptions, 'hide-x': (_options.header || _options.minimizeOptions) && !_menuOptions, 'expand-top': !_options.header && _options.minimizeOptions, 'tabs': _options.datasetTabs }">
        <div *ngIf="showGrid && !editGrid" class="icons">
            <div class="label-title" title="Data Options">Options</div>
            <div *ngIf="_options.settings" class="option" (click)="showDataOptions('settings')">
                <div title="Settings" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'settings' }">settings</div>
            </div>
            <div *ngIf="_options.expandableRows && detailTemplate" class="option">
                <div title="Expand All" (click)="expandAll()" class="sdk-icon">unfold_more</div>
            </div>
            <div *ngIf="_options.expandableRows && detailTemplate" class="option">
                <div title="Collapse All" (click)="collapseAll()" class="sdk-icon">unfold_less</div>
            </div>
            <div *ngIf="_options.columns" class="option" (click)="showDataOptions('columns')">
                <div title="Columns" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'columns' }">list_alt</div>
                <div *ngIf="columnBadge !== '0'" class="badge">
                    <div class="label">{{ columnBadge }}</div>
                </div>
                <div *ngIf="showNote" class="note">
                    <div title="Column * indicates custom name is used." class="label">i</div>
                </div>
            </div>
            <div *ngIf="_options.sorts" class="option" (click)="showDataOptions('sorts')">
                <div title="Sorts" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'sorts' }">sort_by_alpha</div>
                <div *ngIf="sortBadge !== '0'" class="badge">
                    <div class="label">{{ sortBadge }}</div>
                </div>
            </div>
            <div *ngIf="_options.filters" class="option" (click)="showDataOptions('filters')">
                <div title="Filters" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'filters' }">filter_alt</div>
                <div *ngIf="filterBadge !== '0'" class="badge">
                    <div class="label">{{ filterBadge }}</div>
                </div>
            </div>
            <div *ngIf="_options.formulas" class="option" (click)="showDataOptions('formulas')">
                <div title="Formulas" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'formulas' }">functions</div>
                <div *ngIf="formulaBadge !== '0'" class="badge">
                    <div class="label">{{ formulaBadge }}</div>
                </div>
            </div>
            <div *ngFor="let option of optionAddons">
                <ng-container [ngComponentOutlet]="option" [ndcDynamicInputs]="optionInputs" [ndcDynamicOutputs]="optionOutputs"></ng-container>
            </div>
            <div *ngIf="_options.export" class="option" (click)="showDataOptions('export')">
                <div title="Export" class="sdk-icon" [ngClass]="{ 'active': optionTitle === 'export' }">file_download</div>
            </div>
            <div *ngIf="showReset" (click)="resetAllOptions()">
                <div title="Reset All Options" class="reset">RESET</div>
            </div>
            <!-- <div *ngIf="_options.charts" class="divider"></div>
            <div *ngIf="_options.charts" class="option" (click)="toggleDataMode('data')">
                <div title="Grid" class="sdk-icon" [ngClass]="{ 'active': dataMode === 'data' }">storage</div>
            </div>
            <div *ngIf="_options.charts" class="option" (click)="showDataOptions('charts')">
                <div title="Charts" class="sdk-icon" [ngClass]="{ 'active': dataMode === 'charts' }">poll</div>
                <div *ngIf="chartBadge !== '0'" class="badge">
                    <div class="label">{{ chartBadge }}</div>
                </div>
            </div> -->
            <div *ngIf="_options.edit" class="divider"></div>
            <div *ngIf="_options.edit" (click)="editGrid = true">
                <div title="Edit Data" class="sdk-icon">edit_note</div>
            </div>
            <div class="divider"></div>
            <div (click)="loadData('Refresh Data', _rows, _page)">
                <div title="Refresh Data" class="sdk-icon">refresh</div>
            </div>
        </div>
        <div *ngIf="editGrid" class="icons">
            <div class="label-title" title="Edit Options">Edit Mode</div>
            <div (click)="saveData()">
                <div title="Save Data" class="sdk-icon">save</div>
            </div>
            <div (click)="cancelEdit()">
                <div title="Cancel Edit" class="sdk-icon cancel">close</div>
            </div>
        </div>
    </div>

    <!-- Content (Data) -->
    <div class="sdk-datagrid-data" #sdkdatagridcontent [ngClass]="{ 'no-header': !_options.header, 'no-options': !showGrid || !_options.options || (_options.minimizeOptions && !_menuOptions), 'show-options': _menuOptions || (!_options.header && !_options.minimizeOptions && _options.options), 'no-footer': !_options.footer, 'expand-top': _options.options && !_options.header && _options.minimizeOptions, 'tabs': _options.datasetTabs }">
        <!-- Grid -->
        <table *ngIf="!error && showGrid" class="sdk-table">
            <caption style="display: none;">sdk-datagrid</caption>

            <!-- Columns (Headers) -->
            <thead *ngIf="_columns.length > 0">
                <tr>
                    <ng-container *ngFor="let _column of _columns; let i = index">
                        <th *ngIf="_column.isVisible" [title]="(_column.FriendlyName !== '') ? getOriginalColumnName(_column) : ''" [ngClass]="{ 'action-left': _column.isAction && _column.actionSide === 'left', 'action-right': _column.isAction && _column.actionSide === 'right' }">
                            <div *ngIf="!_column.headerTemplate" [style]="(!_column.isAction && _column.showSort ? 'cursor: pointer;': '') + 'display: inline-flex; ' + columnHeaderStyle" (click)="sortColumn(_column)">{{ getColumnName(_column) }} <div *ngIf="_column.Sort" class="sdk-icon" style="font-size: 15px; padding-left: 5px;">{{ (_column.Sort && _column.Sort.direction === "desc") ? "arrow_upward" : "arrow_downward"}}</div></div>
                            <div *ngIf="!_column.headerTemplate && ((_editRecordIndex !== null && _editRecordIndex !== undefined) || editGrid) && _column.notes !== ''" [title]="_column.notes" class="sdk-icon header-info">info</div>
                            <ng-container *ngIf="_column.headerTemplate" [ngTemplateOutlet]="_column.headerTemplate()" [ngTemplateOutletContext]="{ index: i, column: _column, value: getColumnName(_column) }"></ng-container>
                        </th>
                    </ng-container>
                    <th *ngIf="editGrid && _options.delete" class="action-right" style="text-align: center;">
                        <div title="Delete Selected Records" class="sdk-icon delete" (click)="deleteData()">delete</div>
                    </th>
                </tr>
            </thead>

            <!-- No Data -->
            <tbody class="no-records">
                <tr>
                    <td *ngIf="_data.length === 0 && (_columns.length === 0 || hasVisibleColumns().length > 0)" colspan="1000" class="text-left">No records found.</td>
                    <td *ngIf="_columns.length > 0 && hasVisibleColumns().length === 0" colspan="1000">No columns selected.</td>
                </tr>
            </tbody>

            <!-- Data -->
            <tbody class="alternate">
                <ng-container *ngFor="let _record of _data; let i = index">
                    <tr [ngClass]="{ 'row-action': selectedRowActionEvent.observed && (_editRecordIndex === null || _editRecordIndex === undefined) && !editGrid }" (click)="((_editRecordIndex === null || _editRecordIndex === undefined) && !editGrid) ? takeAction({ record: _record, index: i }) : $event.stopPropagation();">
                        <ng-container *ngFor="let _column of _columns">
                            <!-- No Edit -->
                            <td *ngIf="_column.isVisible && (!_column.allowEdit || _column.isAction || (i !== _editRecordIndex && !editGrid))" [class]="_column.isAction ? 'no-hover' : ''" [ngClass]="{ 'action-left': _column.isAction && _column.actionSide === 'left', 'action-right': _column.isAction && _column.actionSide === 'right', 'nowrap': _options.nowrap}" (click)="(_column.isAction) ? $event.stopPropagation() : ''">
                                <div *ngIf="_options.expandableRows && detailTemplate && isFirstColumn(_column)" class="expander" (click)="toggleExpanded(_record)">
                                    <span class="sdk-icon">{{_record.Expanded? 'expand_more' : 'chevron_right'}}</span>
                                </div>
                                <div *ngIf="!_column.dataTemplate" class="expander">
                                    <ng-container *ngIf="!_column.formatData && isColumnObject(_record[_column.Name])">
                                        {{ _record[_column.Name] | json }}
                                    </ng-container>
                                    <ng-container *ngIf="_column.formatData || !isColumnObject(_record[_column.Name])">
                                        {{ _column.formatData? _column.formatData(_record[_column.Name]) : _record[_column.Name]}}
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="_column.dataTemplate" [ngTemplateOutlet]="_column.dataTemplate()" [ngTemplateOutletContext]="{ index: i, record: _record, column: _column, value: _record[_column.Name] }"></ng-container>
                            </td>
                            <!-- Edit -->
                            <td *ngIf="_column.isVisible && _column.allowEdit && !_column.isAction && (i === _editRecordIndex || editGrid)">
                                <div *ngIf="!_column.editTemplate">
                                    <sdk-textbox 
                                        [title]="_record[_column.Name]"
                                        [class]="_column.required ? 'sdk-textbox-required' : 'sdk-textbox'"
                                        padding="5px"
                                        [validCharacters]="_column.validCharacters ?? ''"
                                        [pattern]="_column.pattern ?? ''"
                                        [height]="_column.height === '' ? 'unset' : _column.height"
                                        [width]="_column.width === '' ? 'unset' : _column.width"
                                        [border]="_column.border"
                                        [style]="_column.style"
                                        [value]="_record[_column.Name]"
                                        (changeCallBackEvent)="_record[_column.Name] = $event">
                                    </sdk-textbox>
                                </div>
                                <ng-container *ngIf="_column.editTemplate" [ngTemplateOutlet]="_column.editTemplate()" [ngTemplateOutletContext]="{ index: i, record: _record, column: _column, value: _record[_column.Name] }"></ng-container>
                            </td>
                        </ng-container>
                        <td *ngIf="editGrid && _options.delete" class="action-right" style="text-align: center;">
                            <div (click)="selectRecord(i)">
                                <div class="sdk-icon left">{{ isRecordSelected(i) ? 'check_box' : 'check_box_outline_blank' }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="_options.expandableRows && detailTemplate && _record.Expanded">
                        <td [attr.colspan]="_columns.length" class="row-detail">
                            <ng-container [ngTemplateOutlet]="detailTemplate" [ngTemplateOutletContext]="{ index: i, record: _record }"></ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>

            <!-- Formulas (Footer) -->
            <tfoot *ngIf="hasFormulas().length > 0">
                <tr>
                    <ng-container *ngFor="let _column of _columns">
                        <th *ngIf="_column.isVisible" [ngClass]="{ 'action-left': _column.isAction && _column.actionSide === 'left', 'action-right': _column.isAction && _column.actionSide === 'right' }">
                            <div *ngIf="_column.Formulas">
                                <div *ngFor="let formula of _column.Formulas" class="formula">
                                    <span>{{ formula.operation }}</span>: <span style="font-weight: normal;">{{ formula.value }}</span>
                                </div>
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </tfoot>
        </table>

        <!-- System Errors -->
        <div *ngIf="error" class="system-error" [ngClass]="{ 'no-header': !_options.header, 'no-options': !_options.options, 'no-footer': !_options.footer }">
            <div class="bold">ERROR:</div>
            <div [innerHTML]="error"></div>
            <br />
            <button class="sdk-button" (click)="loadData('reloadData', _rows, _page)">Try Again?</button>
        </div>
    </div>

    <!-- Footer (Paging) -->
    <div *ngIf="_options.footer" class="sdk-datagrid-footer">
        <div *ngIf="showGrid && !editGrid && _options.paging && _total > 0" class="left">
            <sdk-select
                [label]="'Rows'"
                [labelStyle]="'white-space: nowrap'"
                [options]="rowValues"
                [optionStyle]="'width: 100px;'"
                [optionValuesStyle]="'bottom: 0; max-height: 150px; overflow: hidden; overflow: auto;'"
                [selectedOptions]="[_rows]"
                (selectChangeEvent)="getRows($event)">
            </sdk-select>
        </div>
        <div *ngIf="showGrid && !editGrid && _options.paging && _total > 0" class="center">
            <div class="paging">
                <table>
                    <caption style="display: none;">sdk-datagrid-paging</caption>
                    <tbody>
                        <tr>
                            <td><div class="sdk-icon" [disabled]="_page < 2" [ngClass]=" { 'disabled': (_page - 1) === 0 }" (click)="((_page - 1) === 0) ? false : getPage(1)">keyboard_double_arrow_left</div></td>
                            <td><div class="sdk-icon" [disabled]="_page < 2" [ngClass]="{ 'disabled': (_page - 1) === 0 }" (click)="((_page - 1) === 0) ? false : getPage(_page - 1)">navigate_before</div></td>
                            <td>
                                <sdk-select
                                    [options]="totalPages"
                                    [optionStyle]="'width: 175px;'"
                                    [optionValuesStyle]="'bottom: 0; max-height: 200px; overflow: hidden; overflow: auto;'"
                                    [selectedOptions]="[_selectedPage]"
                                    (selectChangeEvent)="getPage($event)">
                                </sdk-select>
                            </td>
                            <td><div class="sdk-icon" [disabled]="_page >= totalPages.length" [ngClass]="{ 'disabled': _page >= totalPages.length }" (click)="_page >= totalPages.length ? false : getPage(_page + 1)">navigate_next</div></td>
                            <td><div class="sdk-icon" [disabled]="_page >= totalPages.length" [ngClass]="{ 'disabled': _page >= totalPages.length }" (click)="_page >= totalPages.length ? false : getPage(totalPages.length)">keyboard_double_arrow_right</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="showGrid" class="right">
            <span style="font-weight: 600; padding-right: 5px;">Total:</span>{{ _total | number:'1.0-0': 'en-US' }}
        </div>
    </div>
</div>

<!-- Option Windows -->
<div *ngIf="_options.options" title="" class="sdk-datagrid-windows" [ngClass]="dataClass">
    <sdk-datagrid-settings-option *ngIf="optionTitle === 'settings'"
        [settings]="_settings"
        [columns]="_columns"
        [customFilters]="_customFilters"
        (closeEvent)="closeDataOptions()"
        (saveEvent)="saveSettings($event)"
        (applyEvent)="applySettingsOptions($event)"></sdk-datagrid-settings-option>

    <sdk-datagrid-columns-option *ngIf="optionTitle === 'columns'"
        [columns]="_columns"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyOptions($event)"></sdk-datagrid-columns-option>

    <sdk-datagrid-sort-option *ngIf="optionTitle === 'sorts'"
        [columns]="_columns"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyOptions($event)"></sdk-datagrid-sort-option>

    <sdk-datagrid-filter-option *ngIf="optionTitle === 'filters'"
        [columns]="_columns"
        [customFilters]="_customFilters"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyOptions($event)"></sdk-datagrid-filter-option>

    <sdk-datagrid-formula-option *ngIf="optionTitle === 'formulas'"
        [columns]="_columns"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyOptions($event)"></sdk-datagrid-formula-option>

    <sdk-datagrid-export-option *ngIf="optionTitle === 'export'"
        [isExporting]="_isExporting"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyExportOptions($event)"></sdk-datagrid-export-option>

    <sdk-datagrid-chart-option *ngIf="optionTitle === 'charts'"
        [columns]="_columns"
        [chart]="null"
        (closeEvent)="closeDataOptions()"
        (applyEvent)="applyOptions($event)"></sdk-datagrid-chart-option>

    <div *ngFor="let window of windowAddons">
        <ng-container [ngComponentOutlet]="window" [ndcDynamicInputs]="windowInputs" [ndcDynamicOutputs]="windowOutputs"></ng-container>
    </div>
</div>

<!-- Lock -->
<div *ngIf="lockGrid || showMessage" title="" class="sdk-datagrid-windows-lock"></div>

<!-- Message -->
<div *ngIf="showMessage" title="" class="sdk-datagrid-message">
    <div class="title">{{ message.Title }}</div>
    <div class="text" [innerHtml]="message.Text"></div>
    <div class="action">
        <button class="sdk-button" (click)="message.OK()">{{ message.OKText }}</button>
        <button *ngIf="message.CancelText" class="sdk-button cancel" (click)="message.Cancel()">{{ message.CancelText }}</button>
    </div>
</div>
