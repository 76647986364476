<div #dropdown class="sdk-select" [ngStyle]="{ display: (labelPosition === 'top') ? 'unset' : 'flex' }" (mouseout)="startClose()" (mouseover)="stopClose()">
    <div *ngIf="label" class="label" [style]="labelStyle">{{ label }}</div>

    <!-- Single Selection -->
    <div *ngIf="!multiSelect" class="box" [style]="optionStyle">
        <div class="selectbox" [title]="titleBuilder()">
            <div #dropdownValue class="dropdown" (click)="setDropdown()">
                <div class="value" *ngIf="!selectedOptions || selectedOptions.length === 0">{{ noValueLabel }}</div>
                <div class="value" *ngIf="selectedOptions && selectedOptions.length > 0">{{ (displayValue) ? selectionBuilder() : selectedOptions }}</div>
                <div class="sdk-icon">expand_more</div>
            </div>
            <div #singleSelect class="values" *ngIf="showDropdown" [style]="adjustedOptionValuesStyle">
                <label *ngFor="let option of options" (click)="selectItem(option)" [ngClass]="{ selected : isSelected(option) }" [title]="(displayValue) ? valueBuilder(option) : option">
                    <div class="value">
                        <div class="value-text">{{ (displayValue) ? valueBuilder(option) : option }}</div>
                        <div *ngIf="!noValueDisabled && isSelected(option)" class="sdk-icon right">check</div>
                    </div>
                </label>
            </div>
        </div>
    </div>

    <!-- Multi Selection -->
    <div *ngIf="multiSelect" class="box" [style]="optionStyle">
        <div class="selectbox" [title]="titleBuilder()">
            <div #dropdownValue class="dropdown" (click)="setDropdown()">
                <div class="value" *ngIf="!selectedOptions || selectedOptions.length === 0">{{ noValueLabel }}</div>
                <div class="value" *ngIf="selectedOptions && selectedOptions.length > 0">{{ selectionBuilder() }}</div>
                <div class="sdk-icon">expand_more</div>
            </div>
            <div #multipleSelect class="values" *ngIf="showDropdown" [style]="adjustedOptionValuesStyle">
                <label class="value-clear-all" (click)="clearSelections()">{{ resetLabel }}</label>
                <label class="value-clear-all" (click)="allSelections()">[all]</label>
                <label *ngFor="let option of options" (click)="selectItem(option)" [ngClass]="{ selected : isSelected(option) }" [title]="(displayValue) ? valueBuilder(option) : option">
                    <div class="sdk-icon left">{{ isSelected(option) ? 'check_box' : 'check_box_outline_blank' }}</div>
                    <div class="value adjust">
                        <div class="value-text">{{ (displayValue) ? valueBuilder(option) : option }}</div>
                    </div>
                </label>
            </div>
        </div>
    </div>
</div>
