import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { AppSettings } from '~/core/models/appsettings';

const FILE = "assets/appsettings.json";
const SETTINGS_KEY = "_AppSettings";

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private http: HttpClient;

    constructor(private httpHandler: HttpBackend) {
        this.http = new HttpClient(this.httpHandler);
    }

    public async init(): Promise<boolean> {
        const p = new Promise<boolean>((resolve, reject) => {
            try {
                let stream$ = this.http.get(FILE);

                lastValueFrom(stream$).then((data: any) => {
                    this.saveSettings(data);

                    resolve(true);
                });
            } catch(error: any) {
                reject(error);
            }
        });

        return await p;
    }

    public async getSettings(initialize: boolean = false): Promise<AppSettings> {
        let storage = sessionStorage.getItem(SETTINGS_KEY);

        if (initialize || !storage) {
            await this.init();

            storage = sessionStorage.getItem(SETTINGS_KEY);
        }

        return JSON.parse(storage!);
    }

    public saveSettings(settings: AppSettings): Promise<void> {
        if (!settings.roles) {
            settings.roles = [];
        }

        return new Promise<void>((resolve) => {
            sessionStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));

            resolve();
        });
    }

    public clearSettings() {
        sessionStorage.removeItem(SETTINGS_KEY);
    }
}
