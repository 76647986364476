import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
	@Output() setLayoutCssClass = new EventEmitter();

	public date = new Date();

	//*************************************************************************
	//  Component Life-Cycle Methods
	//*************************************************************************
	public async ngOnInit() {
	}
}
