import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';

import { DynamicModule } from 'ng-dynamic-component';

//******************************************************************************
//  App Framework
//******************************************************************************
import { AppComponent } from '~/core/app.component';

//******************************************************************************
//  Services
//******************************************************************************
import { AppConfigService } from '~/core/services/appconfig.service';

//******************************************************************************
//  Core Imports
//******************************************************************************
import { CoreImports } from '~/core/classes/imports/core.imports';
import { CoreRouting } from '~/core/classes/routes/core.routing';

//******************************************************************************
//  App Imports
//******************************************************************************
import { AppImports } from '~/classes/imports/app.imports';
import { AppRouting } from '~/classes/routes/app.routing';

//******************************************************************************
//  External Imports
//******************************************************************************
import { ExternalImports } from '~/classes/imports/external.imports';

//******************************************************************************
//  Auth Classes (Guards/Factories)
//******************************************************************************
import { AuthGuard } from '~/core/classes/auth/auth.guard';
import * as auth from '~/core/classes/auth/auth.factories';

const authFactories = new auth.AuthFactories();

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MsalModule,
        DynamicModule,

        CoreImports,
        CoreRouting,

        AppImports,
        AppRouting,

        ExternalImports,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        AppConfigService,
        AuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: authFactories.initializerFactory,
            deps: [AppConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: authFactories.MSALInstanceFactory,
            deps: [AppConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: authFactories.MSALGuardConfigFactory,
            deps: [AppConfigService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: authFactories.MSALInterceptorConfigFactory,
            deps: [AppConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent,
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
