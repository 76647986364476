<div #calendar *ngIf="show" class="sdk-textbox-calendar" [style.height]="((calDays.length * 30) + 30) + 'px'">
    <div class="frame" *ngIf="showCalendar">
        <div class="header">
            <div class="month" (click)="getMonth()">{{ month }}</div>
            <div class="today" (click)="getToday()">Today</div>
            <div class="year" (click)="getYear()">{{ year }}</div>
        </div>
        <div class="calendar">
            <div class="grid">
                <div class="item" [ngClass]="{ today: day.today, selected: day.selected  }" *ngFor="let day of calDays; let i = index" (mousedown)="setDate(day.date)">{{ day.day }}</div>
            </div>
        </div>
    </div>
    
    <table *ngIf="showMonths" class="table">
        <tbody>
            <tr>
                <td (click)="setMonth(0)">Jan</td>
                <td (click)="setMonth(1)">Feb</td>
                <td (click)="setMonth(2)">Mar</td>
                <td (click)="setMonth(3)">Apr</td>
            </tr>
            <tr>
                <td (click)="setMonth(4)">May</td>
                <td (click)="setMonth(5)">Jun</td>
                <td (click)="setMonth(6)">Jul</td>
                <td (click)="setMonth(7)">Aug</td>
            </tr>
            <tr>
                <td (click)="setMonth(8)">Sep</td>
                <td (click)="setMonth(9)">Oct</td>
                <td (click)="setMonth(10)">Nov</td>
                <td (click)="setMonth(11)">Dec</td>
            </tr>
        </tbody>
    </table>

    <table *ngIf="showYears" class="table">
        <tbody>
            <tr>
                <td>
                    <div class="year-box">
                        <input #yearText class="year" type="number" (keydown)="onKeyDown($event)" [value]="year" />
                        <button class="set" (click)="setYear()">SET</button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
