import { Component, inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { AppInsightsService } from '~/core/services/appinsights.service';
import { AppConfigService } from '~/core/services/appconfig.service';
import { AppSettingsService } from '~/core/services/appsettings.service';

import { AppConfig } from '~/core/models/appconfig';
import { AppSettings } from '~/core/models/appsettings';

@Component({
    template: ''
})

export class BaseComponent {
    public appConfig: AppConfig = new AppConfig();
    public appSettings: AppSettings = new AppSettings();
    public permissions: Permissions | undefined;

    public metaTitle: string = "";
    public metaDescription: string = "";
    public metaKeywords: string = "";
    public pageTitle: string = "";
    public isLoading = true;

    private _titleService: Title = inject(Title);
    private _metaService: Meta = inject(Meta);
    private _appInsightsService: AppInsightsService = inject(AppInsightsService);
    private _appConfigService: AppConfigService = inject(AppConfigService);
    private _appSettingsService: AppSettingsService = inject(AppSettingsService);

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        this.appConfig = this._appConfigService.getConfig();
        this.appSettings = await this._appSettingsService.getSettings();

        let ndx = this.appSettings.seo.findIndex((route: any) => `/${route.path}` === this.getPath());

        if (ndx > -1) {
            this.metaTitle = this.appSettings.seo[ndx].metaTitle;
            this.metaDescription = this.appSettings.seo[ndx].metaDescription;
            this.metaKeywords = this.appSettings.seo[ndx].metaKeywords;
            this.pageTitle = this.appSettings.seo[ndx].title;
        }

        this._titleService.setTitle(this.metaTitle);

        this._metaService.updateTag({ name: "description", content: this.metaDescription });
        this._metaService.updateTag({ name: "keywords", content: this.metaKeywords });
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public logAppInsightsEvent(type: string, message: string) {
        this._appInsightsService.logEvent(`User Action: ${type}`, { "Event": message });
    }

    public getPath(): string {
        return window.location.pathname;
    }

    public async saveAppSettings() {
        await this._appSettingsService.saveSettings(this.appSettings);
    }
}
