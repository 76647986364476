import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '~/core/classes/auth/auth.guard';

//******************************************************************************
//  App Pages
//******************************************************************************
import { HomeComponent } from '~/pages/home/home.component';
import { DataComponent } from '~/pages/data/data.component';
import { TabsComponent } from '~/pages/tabs/tabs.component';
import { AboutComponent } from '~/pages/about/about.component';
import { ContactComponent } from '~/pages/contact/contact.component';
import { SitemapComponent } from '~/pages/sitemap/sitemap.component';

export const routes: any[] = [
    {
        path: 'home',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'data',
        component: DataComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'tabs',
        component: TabsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'about',
        component: AboutComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'sitemap',
        component: SitemapComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'notfound',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRouting { }
