<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Export</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="content no-actions">
        <div class="no-box">
            <div class="section">
                <div style="display: inline-block;">
                    <input class="data-option" type="checkbox" [checked]="includeAllData" (change)="includeAllData = !includeAllData">
                    <span class="data-label" (click)="includeAllData = !includeAllData">Include All Data</span>
                </div>
            </div>
            <div class="section">
                <div style="display: inline-block; font-size: 0.8em;">
                    <span>NOTE: Exporting all data may take a while based on the total records in the dataset.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <span *ngIf="isExporting" class="progress">Export in progress... please wait.</span>
        <button *ngIf="!isExporting" class="sdk-button" (click)="apply()">Export</button>
    </div>
</div>
