<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    <div class="data">
        <div>Additional properties can be set to customize the tabs to fit your brand.</div>

        <div class="code">
            <pre><code>// Required properties.
tabs: SDKTab[] = []; // List of tabs.

// Optional properties.
fontFamily: string = ""; // Font to use for the tab titles.
fontColor: string = ""; // Color to use for the tab titles.
tabColor: string = ""; // Color of the tabs.
tabBorderColor: string = ""; // Border color of the tabs.
activeTabColor: string = ""; // Active tab color.
activeFontColor: string = ""; // Active tab font color.
height: string = ""; // Height of tabs.
share: boolean = false; // Enable share icon.
urlParams: string = ""; // Additional Url parameters for sharing.
tabChangedEvent: EventEmitter{{ '<{' }} from: any, to: any {{ '}' }}> = new EventEmitter(); // Callback when active tab changes.</code></pre>
    </div>
    <div>How your HTML tag may look.</div>
    <div class="code">
        <pre><code>{{ '<' }}sdk-tabs
    [share]=true
    fontFamily="'GothamBold', sans-serif, 'Helvetica Neue', Arial"
    fontColor="white"
    tabColor="rgb(68, 75, 13)"
    tabBorderColor="black"
    activeFontColor="rgb(68, 75, 13)"
    activeTabColor="white"
    height="100px"
    [tabs]="tabs">
{{ '<' }}/sdk-tabs></code></pre>
    </div>
</div>
