export enum StorageType {
    Local = "localStorage",
    Session = "sessionStorage"
}

export class SDKDataGridOptions {
    public header?: boolean = true;
    public footer?: boolean = true;
    public paging?: boolean = true;
    public options?: boolean = true;

    public datasetTabs?: boolean = false;

    public autoClosePanel?: boolean = true;
    public minimizeOptions?: boolean = false;
    public expandableRows?: boolean = false;

    public settings?: boolean = false;
    public columns?: boolean = true;
    public filters?: boolean = true;
    public sorts?: boolean = true;
    public formulas?: boolean = true;
    public charts?: boolean = false;
    public export?: boolean = true;
    public edit?: boolean = false;
    public delete?: boolean = true;
    public nowrap?: boolean = false;

    public settingsStorage?: StorageType = StorageType.Local;

    // Maybe remove?
    public panelOverlay?: boolean = true;
    public selectableRows?: boolean = false;
}
