<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    <div class="data">
        <div>Using the <span class="code-font">{{ '<' }}sdk-tabs/></span> component is simple as...</div>
        <ul>
            <li>Define your tabs.</li>
            <li>Add the HTML tag to your page.</li>
        </ul>
        <div>Defining your tabs is easy. It's an array of the Tab object imported from <span class="code-font">{{ '<' }}sdk-tabs/></span> component.</div>
        <div class="code">
            <pre><code>// Properties of the SDKTab object.
SDKTab {{ '{' }}
    title: string; // Name on the tab.
    type?: Type; // The component to display when the tab is selected.
    inputs?: any; // Input properties for the component displayed.
    outputs?: any // Output properties for the component displayed.
{{ '}' }}

// Typescript code to define tabs.
import {{ '{' }} SDKTab {{ '}' }} from 'sdk-tabs';

public tabs: SDKTab[] = [
    {{ '{' }}
        title: "Introduction",
        type: {{ '<' }}any>IntrodutionTabComponent,
        inputs: {{ '{' }}
            "title": "Introducing TABS! The easiest way to organize your layout."
        {{ '}' }},
        outputs: {{ '{' }}
            'tabChangedEvent': (event: any) => {{ '{' }}
                this.tabChanged(event);
            {{ '}' }}
        {{ '}' }}
    {{ '}' }},
    {{ '{' }}
        title: "The Basics",
        type: {{ '<' }}any>TheBasicsTabComponent,
        inputs: {{ '{' }}
            "title": "The Basics - A simple out-of-the-box design."
        {{ '}' }},
        outputs: {{ '{' }}
            'tabChangedEvent': (event: any) => {{ '{' }}
                this.tabChanged(event);
            {{ '}' }}
        {{ '}' }}
    {{ '}' }},
    {{ '{' }}
        title: "Advanced",
        type: {{ '<' }}any>AdvancedTabComponent,
        inputs: {{ '{' }}
            "title": "Advanced Options and Setup."
        {{ '}' }},
        outputs: {{ '{' }}
            'tabChangedEvent': (event: any) => {{ '{' }}
                this.tabChanged(event);
            {{ '}' }}
        {{ '}' }}
    {{ '}' }}
];

public tabChanged(event: any) {{ '{' }}
    // DO SOMETHING HERE WHEN THE TAB CHANGES.
{{ '}' }}
</code></pre>
        </div>
        <div>Adding your HTML tag is just as easy.</div>
        <div class="code">
            <pre><code>{{ '<' }}sdk-tabs [tabs]="tabs">{{ '<' }}/sdk-tabs></code></pre>
        </div>
    </div>
</div>
